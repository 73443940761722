<section class="container">
  <section class="row">
    <!-- ===================Wholesaler message============= -->
    <section
      class="container alert-Influenza-Banner"
      *ngIf="displayWholesalerMessage && wholesalerMessage"
    >
      <section class="row">
        <section class="col-md-11 col-lg-11 col-xl-11">
          <p class="infoBanner-Influenza">
            <span
              class="influenza-Msg-Banner"
              [innerHTML]="wholesalerMessage"
            ></span>
          </p>
        </section>
        <section class="col-md-1 col-lg-1 col-xl-1 alert-close">
          <p
            class="close-button"
            (click)="this.displayWholesalerMessage = false"
          ></p>
        </section>
      </section>
    </section>
    <!-- ===================Left Part====================== -->
    <section
      class="col-lg-2 col-xl-2 reservation-Summary-Section"
      *ngIf="displayActiveSeasons || displayPreviousSeason"
    >
      <!-- Left Part Active Season-->
      <section
        class="container active-season-container"
        *ngIf="displayActiveSeasons"
      >
        <span class="active-Season">
          {{ activeSeasons }}
        </span>
        <section class="row">
          <section
            class="col-12 col-xs-12 col-sm-12 col-lg-12 guid-Row"
            *ngFor="let data of orderHistory"
          >
            <span
              (click)="changeReservation(data, false, activeSeasons)"
              class="guid-Span"
            >
              {{ data.guid }}</span
            >
          </section>
        </section>
        <section
          class="row m-0"
          *ngIf="orderHistory?.length == 0 && dispalayFooterSeason"
        >
          <span class="reserve-Now-Link" (click)="reserveNow()">{{
            "selectVaccines.reserve_now" | cxTranslate
          }}</span>
        </section>
      </section>
      <!-- Left Part Previous Season-->
      <section
        class="container active-season-container"
        *ngIf="displayPreviousSeason"
      >
        <section class="row">
          <section
            class="col-12 guid-Row"
            *ngFor="let season of this.seasonOrders"
          >
            <div
              *ngIf="
                season.orders?.orders?.length >= 1 &&
                season.seasonId != activeSeasonsToShow
              "
            >
              <span class="active-Season">{{
                season.seasonId | hyphenToForwardSlash
              }}</span>
              <div *ngFor="let order of season.orders.orders">
                <span
                  (click)="
                    changeReservation(
                      season.orders.orders[0],
                      true,
                      season.seasonId
                    )
                  "
                  class="guid-Span"
                  >{{ order.guid }}</span
                >
              </div>
            </div>
          </section>
          <br /><br /><br /><br /><br /><br />
        </section>
      </section>
    </section>
    <!-- ===================Middle Part====================== -->
    <section
      class="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-12 col-sm-12 reserve-Div"
      *ngIf="!orderPlaced && !previousHistoryFlag"
    >
      <ng-container *ngIf="displayActiveSeasons || displayPreviousSeason">
        <section class="noReserve-Middle-Section-Header">
          <section>
            <span class="influenza-Vaccine-Span">{{
              "orderConfirmation.infVaccines" | cxTranslate
            }}</span>
          </section>
          <section>
            <span class="influenza-Vaccine-Active-Season-Span"
              >{{ "selectVaccines.season" | cxTranslate }}
              {{ activeSeasons }}</span
            >
          </section>
        </section>
        <section
          class="noReserve-Middle-Section-Content"
          *ngIf="orderHistory?.length == 0 && dispalayFooterSeason"
        >
          <section [innerHTML]="fluParagraph"></section>
          <section class="">
            <button
              class="btn btn-Reserve btn-reserve-align text-button-initial"
              (click)="reserveNow()"
            >
              {{ "selectVaccines.reserve_now" | cxTranslate }}
            </button>
          </section>
        </section>
      </ng-container>
    </section>

    <section
      class="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-12 col-sm-12 reserve-Div-Charts"
    >
      <ng-container *ngIf="orderPlaced || previousHistoryFlag">
        <section
          class="container"
          *ngIf="displayActiveSeasons || displayPreviousSeason"
        >
          <section class="row noReserve-Middle-Section-Header">
            <section class="col-md-8">
              <section>
                <section class="iphone-Center">
                  <span class="influenza-Vaccine-Span"
                    >{{ "selectVaccines.reservation" | cxTranslate }}
                    {{
                      displayActiveSeasons
                        ? idofReservation
                        : idofPreviousReservation
                    }}</span
                  >
                </section>
                <section class="iphone-Center">
                  <span class="influenza-Vaccine-Active-Season-Span"
                    >{{ "selectVaccines.season" | cxTranslate }}
                    {{ seasonDisplay }} {{ statusDisplay }}
                  </span>
                </section>
              </section>
            </section>
            <section class="col-md-4iphone-Center">
              <button
                class="btn resBtn text-button-initial"
                (click)="viewReservation()"
              >
                {{ "orderConfirmation.viewResDetails" | cxTranslate }}
              </button>
            </section>
          </section>
        </section>
        <div class="myDeliveriesHeader myShipmnets-Section">
          <div class="title">
            <h3>{{ "orderConfirmation.myShipments" | cxTranslate }}</h3>
          </div>
          <div class="right-align">
            <ng-container *ngIf="editButtonConfig | async as config">
              <div *ngIf="config?.isUpdateAllowed">
                <a
                  *ngIf="showButton"
                  class="text-button-initial mb-2 text-small editLink"
                  (click)="fluEditReserveUpdate(config?.totalMinDosesThreshold)"
                >
                  <img
                    alt=""
                    src="/assets/icons/editIcon.png"
                    class="edit-Icon-Image"
                  />
                  {{ "orderConfirmation.editReservation" | cxTranslate }}
                </a>
              </div>
            </ng-container>
          </div>
        </div>
        <app-flu-charts
          *ngIf="displayActiveSeasons || displayPreviousSeason"
        ></app-flu-charts>
      </ng-container>
    </section>
    <!-- ==============================Footer Part============================ -->

    <section class="col-lg-12 col-xl-12 footer-Container">
      <app-influenza-dashbaord-footer
        [reserveNowClicked]="reserveNowClicked"
        [displayActiveSeasons]="displayActiveSeasons"
        [dispalayFooterSeason]="dispalayFooterSeason"
        (newItemEvent)="proceedToCategory($event)"
      ></app-influenza-dashbaord-footer>
    </section>
  </section>
</section>
