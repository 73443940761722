import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import * as config from '../shared/config/app.config';
import { BaseService } from '../shared/services/base.service';
import { DataLayerService } from '../shared/services/data-layer.service';

@Injectable({
  providedIn: 'root',
})
export class CustomProdPgService {
  public facets$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public category = new BehaviorSubject<any>(false);
  category$ = this.category.asObservable();
  public cartItems = new BehaviorSubject<any>(false);
  cartItems$ = this.cartItems.asObservable();
  createdCart: any;
  createdCart$: any;
  public minicartUpdate = new Subject<any>();
  public stdCartMsg = new BehaviorSubject<any>(false);
  stdCartMsg$ = this.stdCartMsg.asObservable();
  public cartDetailsUpdate = new BehaviorSubject<any>(false);
  cartDetailsUpdate$ = this.cartDetailsUpdate.asObservable();
  public updateError = new BehaviorSubject<any>(false);
  updateError$ = this.updateError.asObservable();
  constructor(
    private readonly baseService: BaseService,
    private dataLayerService: DataLayerService
  ) {}

  public getCategory(): any {
    this.baseService.get(config.CATEGORY).subscribe((res) => {
      this.category.next(res);
    });
  }
  public updateCart(productDetails, userId, cartId): any {
    this.updateError.next(false);
    this.cartDetailsUpdate.next(false);
    this.minicartUpdate.next(false);
    this.stdCartMsg.next(false);
    let addType;
    if(productDetails?.addToCartType != undefined){
       addType = productDetails.addToCartType;
    } else {
       addType = "plp";
    }
    const addToCartType = 
    this.baseService
      .post(
        config.USER_ACCOUNT +
          userId +
          '/carts/' +
          cartId +
          '/entries?fields=DEFAULT',
        productDetails
      )
      .subscribe(
        (res) => {
          if (res) {
            this.cartDetailsUpdate.next(true);
            this.minicartUpdate.next(cartId);
            this.stdCartMsg.next(true);
            this.dataLayerService.addCartDataLayer(
              res,
              'standard process',
              productDetails.quantity,
              addType
            );
          }
        },
        (err) => {
          this.updateError.next(err);
        }
      );
  }
  getUpdateMiniCart(): Observable<any> {
    return this.minicartUpdate.asObservable();
  }
  getCarts(userId): void {
    this.cartItems.next(false);
    if (userId) {
      this.baseService
        .get(
          config.USER_ACCOUNT +
            userId +
            '/carts?currentPage=0&fields=DEFAULT&pageSize=20&savedCartsOnly=false'
        )
        .subscribe((res) => {
          this.cartItems.next(res);
        });
    }
  }
  createCart(userId): void {
    this.createdCart = new BehaviorSubject<any>(false);
    this.createdCart$ = this.createdCart.asObservable();
    this.baseService
      .post(config.USER_ACCOUNT + userId + '/carts?fields=DEFAULT', {})
      .subscribe((res) => {
        this.createdCart.next(res);
      });
  }
}
