import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgbAccordionItem } from '@ng-bootstrap/ng-bootstrap';
import { FaqService } from '../faq.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
})
export class FaqComponent implements OnInit, OnDestroy {
  @ViewChild('a') accordion: NgbAccordionItem;
  faq = [];
  openById = {};
  expandAll = true;
  FaqDataSubscription: any;

  ITEM_CHILD_CLASS = '.item-child-answer'

  constructor(
    private readonly faqService: FaqService,
    private readonly cd: ChangeDetectorRef,
    private elemRef: ElementRef
  ) {}

  ngOnInit() {
    this.faqService.getFAQ();
    this.FaqDataSubscription = this.faqService.faqList$.subscribe((res) => {
      if (res !== null) {
        this.faq = res;
        this.cd.detectChanges();
      }
    });
  }

  showItem(e): void {
    e.target.classList.contains('active')
      ? e.target.classList.remove('active')
      : e.target.classList.add('active');
    const itemChild = e.target.parentElement.parentElement.querySelector(
      '.item-child-container'
    );
    itemChild.classList.contains('show')
      ? itemChild.classList.remove('show')
      : itemChild.classList.add('show');
  }

  showAllChild(e): void {
    const targetItem =
      e.target.parentElement.parentElement.querySelectorAll('.item-child');

    targetItem.forEach((element) => {
      element.querySelector(this.ITEM_CHILD_CLASS).classList.add('show');
      element.querySelector('.child-btn').classList.add('active');
    });
    e.target.classList.remove('show');
    e.target.nextSibling.classList.add('show');
  }
  hideAllChild(e): void {
    const targetItem =
      e.target.parentElement.parentElement.querySelectorAll('.item-child');
    targetItem.forEach((element) => {
      element.querySelector(this.ITEM_CHILD_CLASS).classList.remove('show');
      element.querySelector('.child-btn').classList.remove('active');
    });
    e.target.classList.remove('show');
    e.target.previousSibling.classList.add('show');
  }

  toggleChild(e): void {
    const targetItem =
      e.target.parentElement.parentElement.parentElement.querySelector(
        this.ITEM_CHILD_CLASS
      );
    if (targetItem.classList.contains('show')) {
      targetItem.classList.remove('show');
      e.target.classList.remove('active');
    } else {
      targetItem.classList.add('show');
      e.target.classList.add('active');
    }
  }

  ngOnDestroy() {
    this.FaqDataSubscription.unsubscribe();
  }
}
