import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbPopover, NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';
import {
  GlobalMessageService,
  GlobalMessageType,
  RoutingService,
  TranslationService,
} from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Subscription } from 'rxjs';
import { MaximumQuantityInputError } from 'src/app/shared/errors/MaximumQuantityInputError';
import { MinimumQuantityInputError } from 'src/app/shared/errors/MinimumQuantityInputError';
import { QuantityInputError } from 'src/app/shared/errors/QuantityInputError';
import { BaseService } from 'src/app/shared/services/base.service';
import { DataLayerService } from 'src/app/shared/services/data-layer.service';
import { ProductUtils } from '../../shared/utils/productUtils';
import { CustomProdPgService } from '../custom-prod-pg.service';
import { ProductInfo } from './custom-product-card.model';

@Component({
  selector: 'app-custom-product-card',
  templateUrl: './custom-product-card.component.html',
  styleUrls: ['./custom-product-card.component.scss'],
})
export class CustomProductCardComponent implements OnInit, OnDestroy {
  sessionStorage = sessionStorage;
  public productInfo: ProductInfo = null;
  buttonName = 'Login to order';
  description: any;
  monograph: any;
  itemInCart: boolean = false;
  monographLink: any;
  @ViewChild('p2') public successPopover: NgbPopover;
  fallbackImage = '../../assets/i18n-assets/sanofi_missing_product.png';
  userDetails: any;
  @Input() iconDisplay: boolean;
  @Input() hospital: boolean;
  @Input() set product(p: ProductInfo) {
    this.productInfo = p;
  }
  batchInfo: {};
  itemAddedQuantity: any;
  cartId: any;
  cartItems: any;
  successMsg = false;
  updateErrorSub: Subscription;
  userDataSub: Subscription;
  cartItemsSub: Subscription;
  createdCartSub: Subscription;
  updatedCartSub: Subscription;
  boxesQuantity = 0;
  activeFluSeason: boolean;
  userInput: string = '';
  roundedValue: number | null = null;
  dividedValue: number | null = null;

  BUTTON_NAMES = {
    RESERVE_NOW: 'Reserve now',
    ADD_TO_CART: 'Add to cart'
  }

  constructor(
    private readonly user: UserAccountFacade,
    private readonly baseService: BaseService,
    private readonly config: NgbPopoverConfig,
    private readonly routingService: RoutingService,
    private readonly plpService: CustomProdPgService,
    private readonly cdr: ChangeDetectorRef,
    private readonly sanitizer: DomSanitizer,
    private readonly translation: TranslationService,
    private globalMessage: GlobalMessageService,
    private readonly dataLayerService: DataLayerService
  ) {
    config.closeDelay = 2000;
    config.openDelay = 1000;
  }

  ngOnInit(): void {
    this.activeFluSeason = JSON.parse(sessionStorage.getItem('activeFluSeason'));
    this.batchInfo = this.productInfo.batchInfo;
    this.monograph = this.productInfo.productDocuments;
    const calculatedQty = Math.ceil(this.productInfo.quantityPerUnit / this.productInfo.numberOfDosesPerUnit);
    this.boxesQuantity =  calculatedQty === 0 ? 1 : calculatedQty
    this.itemInCart = true;
    if (this.monograph) {
      for (const link of this.monograph?.productDocument) {
        this.monographLink = this.sanitizer.bypassSecurityTrustResourceUrl(
          link.url
        );
      }
    }
    this.description = this.productInfo.description;
    this.userDataSub = this.user.get().subscribe((res) => {
      this.userDetails = res;
    if (res !== undefined) {
            if (this.isIndirect() && this.isIndirectPage()) {
              this.buttonName = this.BUTTON_NAMES.ADD_TO_CART;
            } else if (this.isIndirect() && !this.hospital) { // Change 1: Added this condition
              this.buttonName = this.BUTTON_NAMES.RESERVE_NOW;
            } else if (this.productInfo.flu === true && (!this.hospital || (this.hospital && this.activeFluSeason))) {
              this.buttonName = this.BUTTON_NAMES.RESERVE_NOW;
            } else {
              this.buttonName = this.BUTTON_NAMES.ADD_TO_CART;
            }
          } else {
            this.buttonName = 'Login to order';
          }
        });
    this.cartItemsSub = this.plpService.cartItems$.subscribe((res) => {
      if (res) {
        this.cartItems = res?.carts;
        this.cartItems = this.cartItems.filter(
          (item) => item?.saveTime === undefined
        );
      }
    });
  }
  navigateToFlu() {
    this.routingService.goByUrl('/influenza/dashboard');
  }

  navigateToIndirectCatalog(): void {
    this.routingService.goByUrl('/Open-Catalogue/c/1?query=:relevance:all:1:productType:INDIRECT');
  }
  performQuantityComputation(
    product,
    operator,
    idData,
    stepQuantity,
  ): void {
    try {
      ProductUtils.alterQuantity(
        operator,
        idData,
        stepQuantity,
        Number.MIN_VALUE,
        Number.MAX_VALUE,
        'cart',
        product.numberOfDosesPerUnit

      );
      this.roundToNearestMultipleOfStepQuantity(stepQuantity);
      this.userInput = this.roundedValue.toString();
      
      const inputElement = document.getElementById(`prodQuantity${this.productInfo.code}`) as HTMLInputElement;
                      this.updateButtonStates(stepQuantity);
                      this.cdr.detectChanges();

    } catch (e: any) {
      if (e instanceof QuantityInputError) {
        this.translation
          .translate('addToCartItems.inputStepQuantityErrorMsg')
          .subscribe((res) => {
            this.globalMessage.add(
              product.name + ':' + res,
              GlobalMessageType.MSG_TYPE_ERROR
            );
          });
      } else if (e instanceof MinimumQuantityInputError) {
        this.translation
          .translate('addToCartItems.inputMinimumQuantityErrorMsg')
          .subscribe((res) => {
            this.globalMessage.add(
              product.name + ':' + res,
              GlobalMessageType.MSG_TYPE_ERROR
            );
          });
      } else if (e instanceof MaximumQuantityInputError) {
        this.translation
          .translate('addToCartItems.inputMaximumQuantityErrorMsg')
          .subscribe((res) => {
            this.globalMessage.add(
              product.name + ':' + res,
              GlobalMessageType.MSG_TYPE_ERROR
            );
          });
      }
    }
  }

  goToLogin() {
    this.routingService.goByUrl('/login');
  }

  addToCart(productCode, popover): void {
  this.successMsg = true;
  if (this.buttonName === this.BUTTON_NAMES.RESERVE_NOW) {
    if (this.productInfo?.productType === 'INDIRECT') {
      this.navigateToIndirectCatalog();
    } else {
      this.navigateToFlu();
    }
  } else if (this.buttonName === this.BUTTON_NAMES.ADD_TO_CART) {
    const inputElement = document.getElementById(`prodQuantity${productCode}`) as HTMLInputElement;
    const quantity = inputElement ? parseInt(inputElement.value, 10) : 0;

    const params = {
      product: { code: productCode },
      quantity: quantity
    };

    if (this.isIndirect()) {
      params['selectedB2BUnit'] = this.userDetails.wholesalerId;
    }
    this.itemAddedQuantity = quantity;
    this.user.get().subscribe((customer: any) => {
      if (customer) {
        const restrictionArray = [{ productCode: productCode }];
        const restrictionParams = { restrictions: restrictionArray };

        this.baseService.post(`/products/getRestrictions?fields=DEFAULT&emulatedUser=${customer.userName}`, restrictionParams).subscribe((res) => {
          if (res && res.restrictions.length > 0) {
            const minQuantity = res.restrictions[0].minQuantity;
            const maxQuantity = res.restrictions[0].maxQuantity;
            if (quantity < minQuantity || quantity > maxQuantity) {
              const errorMsgKey = quantity < minQuantity ? 'addToCartItems.inputMinimumQuantityErrorMsg' : 'addToCartItems.inputMaximumQuantityErrorMsg';
              this.translation.translate(errorMsgKey).subscribe((errorMsg) => {
                this.globalMessage.add(`${this.productInfo?.name}: ${errorMsg}`, GlobalMessageType.MSG_TYPE_ERROR);
              });
              return;
            }
          }
          if (!this.cartItems || this.cartItems.length === 0) {
            this.plpService.createCart(this.userDetails.userName);
            this.createdCartSub = this.plpService.createdCart$.subscribe((cartRes) => {
              if (cartRes) {
                this.cartId = cartRes.code;
                this.plpService.updateCart(params, this.userDetails.userName, this.cartId);
                this.successPopover.open();
                setTimeout(() => this.successPopover.close(), 2500);
                this.updatedCartSub = this.plpService.cartDetailsUpdate$.subscribe((updateRes) => {
                  if (updateRes) {
                    // this.successPopover.open();
                    // setTimeout(() => this.successPopover.toggle(), 2500);
                  }
                });
              }
            });
          } else {
            this.cartId = this.cartId || (this.cartItems.length > 0 ? this.cartItems[this.cartItems.length - 1].code : undefined);
            this.plpService.updateCart(params, this.userDetails.userName, this.cartId);
            this.successPopover.open();
            setTimeout(() => this.successPopover.close(), 2500);
            this.updatedCartSub = this.plpService.cartDetailsUpdate$.subscribe((res) => {
              if (res) {
                // this.successPopover.open();
                // setTimeout(() => this.successPopover.toggle(), 2500);
              }
            });
          }
        });
      }
    });
  }
}

  UpdateCartDetails(params) {
    this.plpService.updateCart(params, this.userDetails?.userName, this.cartId);
    this.updatedCartSub = this.plpService.cartDetailsUpdate$.subscribe(
      (res) => {
        if (res) {
          if (this.successMsg) {
            this.successPopover.open();
            setTimeout(() => this.successPopover.close(), 2500);
            // popover.remove();
            this.successMsg = false;
          }
        }
      }
    );
    this.updateErrorSub = this.plpService.updateError$.subscribe((res) => {
      if (res) {
        this.successMsg = false;
      }
    });
  }

  roundToNearestMultipleOfStepQuantity(stepQuantity: number, idData?) {
        const parsedInput = parseFloat(this.userInput);
        if (!isNaN(parsedInput) && parsedInput > 0) {
          if (parsedInput % stepQuantity !== 0) {
            this.roundedValue = Math.ceil(parsedInput / stepQuantity) * stepQuantity;
          } else {
            this.roundedValue = parsedInput;
          }
          
          const noOfDoses = document.getElementById(
            `noOfDoses${idData}`
          ) as HTMLInputElement;
          if (this.userInput !== '') {
            const qty = Math.ceil(parsedInput / stepQuantity)
            noOfDoses.innerHTML = `${qty} boîte(s)`
          } else {
            noOfDoses.innerHTML = `${stepQuantity} boîte(s)`;
          }

          if (this.roundedValue !== null) {
            this.userInput = this.roundedValue.toString();
            this.dividedValue = this.roundedValue / stepQuantity;
          }
        } else {
          // Handle invalid input
          this.roundedValue = null;
          this.dividedValue = null;
        }
        this.updateButtonStates(stepQuantity);
        this.cdr.detectChanges();
      }

 updateButtonStates(stepQuantity: number): void {
   const inputElement = document.getElementById(`prodQuantity${this.productInfo.code}`) as HTMLInputElement;
   const quantity = parseInt(inputElement.value, 10) || 0;

   const minusButton = document.getElementById(`minusIcon${this.productInfo.code}`);
   const plusButton = document.getElementById(`plusIcon${this.productInfo.code}`);

   // Enable minus button if quantity is greater than the minimum step quantity
   if (quantity > stepQuantity) {
     minusButton?.classList.remove('disabled');
   } else {
     minusButton?.classList.add('disabled');
   }

   // Enable plus button if quantity is less than some max value (if applicable)
   // Adjust max quantity logic as needed
   if (quantity < 9999) {
     plusButton?.classList.remove('disabled');
   } else {
     plusButton?.classList.add('disabled');
   }
 }


  isIndirect(): boolean {
    return this.productInfo?.productType && this.productInfo?.productType === 'INDIRECT';
  }
  isIndirectPage(): boolean {
    return window.location.href.indexOf('productType:INDIRECT') != -1;
  }

  itemSelected(info) {
    this.routingService.goByUrl('/product/' + info.code + '/' + info.code);
    this.dataLayerService.selectItemDataLayer(info, 'standard process');
  }
  ngOnDestroy() {
    this.updateErrorSub?.unsubscribe();
    this.userDataSub?.unsubscribe();
    this.cartItemsSub?.unsubscribe();
    this.createdCartSub?.unsubscribe();
    this.updatedCartSub?.unsubscribe();
  }
}
