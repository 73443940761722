import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { CmsService, OccEndpointsService } from '@spartacus/core';
import { CustomInfluenzaService } from '../custom-influenza.service';
@Component({
  selector: 'app-splitted-deliveries-paragraph',
  templateUrl: './splitted-deliveries-paragraph.component.html',
  styleUrls: ['./splitted-deliveries-paragraph.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SplittedDeliveriesParagraphComponent implements OnInit {
  cmsResponse: any = '';
  deliveries: any;
  constructor(
    private readonly cmsData: CmsService,
    private readonly occEndPoint: OccEndpointsService,
    private readonly influenzaService: CustomInfluenzaService,
    private readonly cdr: ChangeDetectorRef
  ) {}

   ngOnInit(): void {

   const isModifyJourney = history.state.minThreshold !== undefined || this.influenzaService.getInfluenzaThreshold() !== undefined;

    const componentUid = isModifyJourney
        ? 'ModifyOrderFluMessageComponent'
        : 'SplittedDeliveriesParagraphComponent';

      this.cmsData.getComponentData(componentUid).subscribe((res) => {
        this.cmsResponse = res;
        this.deliveries = this.cmsResponse?.content;
        this.cdr.detectChanges();
      });
    }
  }
