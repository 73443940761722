import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-total-prices-order',
  templateUrl: './total-prices-order.component.html',
  styleUrls: ['./total-prices-order.component.scss'],
})
export class TotalPricesOrderComponent {
  @Input() orderDetails: any;
}
