import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-total-prices',
  templateUrl: './total-prices.component.html',
  styleUrls: ['./total-prices.component.scss'],
})
export class TotalPricesComponent {
  @Input() abstractOrder: any;
}
