<div class="col-xs-12 col-sm-12 col-md-12 Registration-split user-information-view">
  <h3 class="pdp-title">
    {{ "userRegistration.userInformation.header" | cxTranslate }}
  </h3>

  <div id="location-box" *ngIf="!!accountData">
    <div class="locationIcon">
      <img src="../../assets/icons/location.svg" alt="location" />
    </div>
    <div class="locationDetails">
      <span class="selected-loc">{{ accountData?.name }} ({{ accountData?.uid }})</span>
      <span class="change-loc focus" data-toggle="modal" data-keyboard="true" data-target="#location-sel-popup"
        tabindex="0">
        {{
        "userRegistration.userInformation.locationStripSubMessage"
        | cxTranslate
        }}
      </span>
    </div>
  </div>

  <span class="title-sub-heading">{{
    "userRegistration.userInformation.formTitle" | cxTranslate
    }}</span>

  <form [formGroup]="userInfoForm" id="userInfoForm" (ngSubmit)="submitUserInfo()">
    <div class="col-xs-12 col-sm-9 col-md-7 p0">
      <div id="registerBilling">
        <div formGroupName="userInfoDetails">
          <div class="
              col-xs-12 col-sm-12 col-md-12
              p0
              Fields-container
              doubleLineContainer
            ">
            <label for="username" class="Fields-label icon-label">{{
              "userRegistration.userInformation.username.label" | cxTranslate
              }}
            </label>
            <ng-template #popContent>{{
              "userRegistration.userInformation.username.popover_msg_1"
              | cxTranslate
              }}
              <br />{{
              "userRegistration.userInformation.username.popover_msg_2"
              | cxTranslate
              }}
              <br />{{
              "userRegistration.userInformation.username.popover_msg_3"
              | cxTranslate
              }}</ng-template>
            <a placement="top" [ngbPopover]="popContent" class="Fields-label icon-label"><img
                src="../../assets/icons/faqs_reg.png" alt="faq_reg" class="faq" /></a>
            <input id="username" name="userName" data-valid="valid_facility"
              aria-labelledby="userName tooltipFacilityName" type="text" class="Fields-input user" autocomplete="off"
              formControlName="userName" data-di-id="#userName" [ngModel]="janrainUserData?.userName"
              [attr.readonly]="janrainUserData?.userName ? '' : null" />

            <span *ngIf="
                isUsernameExists &&
                !userInfoForm.get('userInfoDetails.userName').touched
              " class="errorMessage">
              {{
              "userRegistration.userInformation.username.existingUsernameMsg"
              | cxTranslate
              }}</span>

            <span *ngIf="
                !!userInfoForm.get('userInfoDetails.userName').value &&
                !userInfoForm.get('userInfoDetails.userName').valid &&
                userInfoForm.get('userInfoDetails.userName').errors
                  ?.sameAsaccountNo &&
                userInfoForm.get('userInfoDetails.userName').touched
              " class="errorMessage">{{
              "userRegistration.userInformation.username.usernameSameAsAccountNo"
              | cxTranslate
              }}</span>

            <span *ngIf="
                !!userInfoForm.get('userInfoDetails.userName').value &&
                !userInfoForm.get('userInfoDetails.userName').valid &&
                !userInfoForm.get('userInfoDetails.userName').errors
                  ?.sameAsaccountNo &&
                userInfoForm.get('userInfoDetails.userName').touched
              " class="errorMessage">{{
              "userRegistration.userInformation.username.inValidUsername"
              | cxTranslate
              }}</span>
            <span *ngIf="
                userInfoForm.get('userInfoDetails.userName').errors?.required &&
                !userInfoForm.get('userInfoDetails.userName').valid &&
                userInfoForm.get('userInfoDetails.userName').touched
              " class="defaultMessage" role="alert" aria-live="assertive">{{
              "userRegistration.userInformation.requiredMsg" | cxTranslate
              }}
            </span>
          </div>

          <div class="
              col-xs-12 col-sm-12 col-md-12
              p0
              Fields-container
              doubleLineContainer
            " *ngIf="!formDetails.get('wholeSalerFlag').value">
            <label for="userPassword" class="Fields-label">{{
              "userRegistration.userInformation.password.label" | cxTranslate
              }}
            </label>
            <input id="userPassword" name="password" data-valid="valid_facility"
              aria-labelledby="password tooltipFacilityName" type="password" class="Fields-input" autocomplete="off"
              formControlName="password" data-di-id="#userPassword" />

            <span *ngIf="
                !!userInfoForm.get('userInfoDetails.password').value &&
                userInfoForm.get('userInfoDetails.password').valid &&
                !userInfoForm.get('userInfoDetails.password').errors
                  ?.sameAsaccountNo &&
                userInfoForm.get('userInfoDetails').errors
                  ?.userNamePasswordMatch &&
                userInfoForm.get('userInfoDetails.password').touched
              " class="errorMessage">{{
              "userRegistration.userInformation.password.usernameAndPasswordNotSame"
              | cxTranslate
              }}</span>

            <span *ngIf="
                !!userInfoForm.get('userInfoDetails.password').value &&
                !userInfoForm.get('userInfoDetails.password').valid &&
                userInfoForm.get('userInfoDetails.password').errors
                  ?.sameAsaccountNo &&
                userInfoForm.get('userInfoDetails.password').touched
              " class="errorMessage">{{
              "userRegistration.userInformation.password.passwordSameAsAccountNo"
              | cxTranslate
              }}</span>

            <span *ngIf="
                !userInfoForm.get('userInfoDetails.password').valid &&
                !userInfoForm.get('userInfoDetails.password').errors
                  ?.required &&
                !userInfoForm.get('userInfoDetails.password').errors
                  ?.sameAsaccountNo &&
                userInfoForm.get('userInfoDetails.password').touched
              " class="errorMessage">{{
              "userRegistration.userInformation.password.inValidPassword"
              | cxTranslate
              }}</span>
            <span *ngIf="
                userInfoForm.get('userInfoDetails.password').errors?.required &&
                !userInfoForm.get('userInfoDetails.password').valid &&
                userInfoForm.get('userInfoDetails.password').touched
              " class="defaultMessage" role="alert" aria-live="assertive">{{
              "userRegistration.userInformation.requiredMsg" | cxTranslate
              }}
            </span>
          </div>

          <div class="
              col-xs-12 col-sm-12 col-md-12
              p0
              Fields-container
              zip-container
            " *ngIf="!formDetails.get('wholeSalerFlag').value">
            <label for="userConfirmPassword" class="Fields-label">{{
              "userRegistration.userInformation.confirmPassword.label"
              | cxTranslate
              }}</label>
            <input id="userConfirmPassword" name="confirmPassword" data-valid="valid_zip_canada" type="password"
              class="Fields-input" autocomplete="off" formControlName="confirmPassword"
              data-di-id="#userConfirmPassword" />

            <span *ngIf="
                !userInfoForm.get('userInfoDetails.confirmPassword').valid &&
                !userInfoForm.get('userInfoDetails.confirmPassword').errors
                  ?.required &&
                userInfoForm.get('userInfoDetails.confirmPassword').errors
                  ?.isPasswordInvalid &&
                userInfoForm.get('userInfoDetails.confirmPassword').touched
              " class="errorMessage">{{
              "userRegistration.userInformation.password.inValidPassword"
              | cxTranslate
              }}</span>

            <span *ngIf="
                !!userInfoForm.get('userInfoDetails.confirmPassword').value &&
                userInfoForm.get('userInfoDetails.confirmPassword').touched &&
                !userInfoForm.get('userInfoDetails.confirmPassword').errors
                  ?.isPasswordInvalid &&
                userInfoForm.get('userInfoDetails').errors?.mismatch
              " class="errorMessage">{{
              "userRegistration.userInformation.notMatchMsg" | cxTranslate
              }}</span>
            <span *ngIf="
                userInfoForm.get('userInfoDetails.confirmPassword').errors
                  ?.required &&
                !userInfoForm.get('userInfoDetails.confirmPassword').valid &&
                userInfoForm.get('userInfoDetails.confirmPassword').touched
              " class="defaultMessage" role="alert" aria-live="assertive">{{
              "userRegistration.userInformation.requiredMsg" | cxTranslate
              }}
            </span>
          </div>
        </div>
        <div class="
            col-xs-12 col-sm-12 col-md-12
            p0
            Fields-container
            asses_dropdown
          ">
          <label for="userPasswordRecoverQuestion" id="userPasswordQuestionLabel" class="Fields-label">{{
            "userRegistration.userInformation.passwordRecovery.question.label"
            | cxTranslate
            }}</label>
          <div class="">
            <div class="">
              <ng-select name="passwordQuestion" [items]="passwordQuestions" [searchable]="false" [clearable]="false"
                bindLabel="name" bindValue="code"
                placeholder="{{'userRegistration.userInformation.select'| cxTranslate}}"
                formControlName="passwordQuestion" class="dropdown-credit"></ng-select>
            </div>
            <span *ngIf="
                userInfoForm.get('passwordQuestion').errors?.required &&
                !userInfoForm.get('passwordQuestion').valid &&
                userInfoForm.get('passwordQuestion').touched
              " class="errorMessage pt-2" role="alert" aria-live="assertive">{{
              "userRegistration.userInformation.requiredMsg" | cxTranslate
              }}
            </span>
          </div>
        </div>
        <div class="
            col-xs-12 col-sm-12 col-md-12
            mt-2
            p0
            Fields-container
            zip-container
          ">
          <label for="userPasswordAnswer" class="Fields-label">{{
            "userRegistration.userInformation.passwordRecovery.answer.label"
            | cxTranslate
            }}</label>
          <input id="userPasswordAnswer" name="passwordAnswer" data-valid="valid_zip_canada" type="text"
            class="Fields-input" autocomplete="off" formControlName="passwordAnswer" data-di-id="#userPasswordAnswer" />

          <span *ngIf="
              userInfoForm.get('passwordAnswer').errors?.required &&
              !userInfoForm.get('passwordAnswer').valid &&
              userInfoForm.get('passwordAnswer').touched
            " class="defaultMessage" role="alert" aria-live="assertive">{{
            "userRegistration.userInformation.requiredMsg" | cxTranslate }}
          </span>
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-sm-5 col-md-5 password-security focusnone" id="passwordDesc" tabindex="-1">
      <app-security-information [formDetails]="formDetails.get('wholeSalerFlag').value"></app-security-information>
    </div>
    <hr class="Fields-container-line billingPage" />
    <h3 class="pdp-title">
      {{ "userRegistration.userInformation.subTitle" | cxTranslate }}
    </h3>
    <div class="col-xs-12 col-sm-9 col-md-7 p0">
      <div id="registerBilling">
        <div class="
            col-xs-12 col-sm-12 col-md-12
            p0
            Fields-container
            doubleLineContainer
          ">
          <label for="userFirstName" class="Fields-label">{{
            "userRegistration.userInformation.firstName.label" | cxTranslate
            }}
          </label>
          <input id="userFirstName" name="firstName" data-valid="valid_facility"
            aria-labelledby="firstName tooltipFacilityName" type="text" class="Fields-input" autocomplete="off"
            formControlName="firstName" data-di-id="#userFirstName" [ngModel]="janrainUserData?.firstName" />

          <span *ngIf="
              userInfoForm.get('firstName').errors?.required &&
              !userInfoForm.get('firstName').valid &&
              userInfoForm.get('firstName').touched
            " class="defaultMessage" role="alert" aria-live="assertive">{{
            "userRegistration.userInformation.requiredMsg" | cxTranslate }}
          </span>
        </div>

        <div class="
            col-xs-12 col-sm-12 col-md-12
            p0
            Fields-container
            doubleLineContainer
          ">
          <label for="userLastName" class="Fields-label">{{
            "userRegistration.userInformation.lastName.label" | cxTranslate
            }}
          </label>
          <input id="userLastName" name="lastName" data-valid="valid_facility"
            aria-labelledby="lastName tooltipFacilityName" type="text" class="Fields-input" autocomplete="off"
            formControlName="lastName" data-di-id="#userLastName" [ngModel]="janrainUserData?.lastName" />

          <span *ngIf="
              userInfoForm.get('lastName').errors?.required &&
              !userInfoForm.get('lastName').valid &&
              userInfoForm.get('lastName').touched
            " class="defaultMessage" role="alert" aria-live="assertive">{{
            "userRegistration.userInformation.requiredMsg" | cxTranslate }}
          </span>
        </div>
        <div class="
            hidden
            col-xs-12 col-sm-12 col-md-12
            p0
            Fields-container
            asses_dropdown
          ">
          <label for="primaryRole" id="roleLabel" class="Fields-label">{{
            "userRegistration.userInformation.role.label" | cxTranslate
            }}</label>
          <div class="">
            <div class="">
              <ng-select name="role" [items]="contactRole" [searchable]="false" [clearable]="false" bindLabel="name"
                bindValue="code" placeholder="Select" formControlName="role" class="dropdown-credit"></ng-select>
            </div>
            <span *ngIf="
                userInfoForm.get('role').errors?.required &&
                !userInfoForm.get('role').valid &&
                userInfoForm.get('role').touched
              " class="errorMessage pt-2" role="alert" aria-live="assertive">{{
              "userRegistration.userInformation.requiredMsg" | cxTranslate
              }}
            </span>
          </div>
        </div>
        <div formGroupName="emailInfo" *ngIf="!formDetails.get('wholeSalerFlag').value">
          <div class="col-xs-12 col-sm-12 col-md-12 p0 Fields-container mt-2">
            <label for="userEmail" class="Fields-label">{{
              "userRegistration.userInformation.email.label" | cxTranslate
              }}
            </label>
            <input id="userEmail" name="email" data-valid="valid_mail" type="text" class="Fields-input"
              data-view="newemail" formControlName="email" data-di-id="#userEmail" />

            <span class="italic-warning">{{"userRegistration.userInformation.email.emailWarning" | cxTranslate}}</span>

            <span *ngIf="
                isEmailExists && !userInfoForm.get('emailInfo.email').touched
              " class="errorMessage">
              {{
              "userRegistration.userInformation.email.existingEmail"
              | cxTranslate
              }}</span>

            <span *ngIf="
                !!userInfoForm.get('emailInfo.email').value &&
                userInfoForm.get('emailInfo.email').invalid &&
                userInfoForm.get('emailInfo.email').touched
              " class="defaultMessage" role="alert" aria-live="assertive">{{
              "userRegistration.userInformation.email.invalidEmail"
              | cxTranslate
              }}</span>
            <span *ngIf="
                userInfoForm.get('emailInfo.email').errors?.required &&
                !userInfoForm.get('emailInfo.email').valid &&
                userInfoForm.get('emailInfo.email').touched
              " class="defaultMessage" role="alert" aria-live="assertive">{{
              "userRegistration.userInformation.requiredMsg" | cxTranslate
              }}
            </span>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 p0 Fields-container">
            <label for="userConfirmEmail" class="Fields-label">{{
              "userRegistration.userInformation.confirmEmail.label"
              | cxTranslate
              }}
            </label>
            <input id="userConfirmEmail" name="confirmEmail" data-valid="valid_mail" type="text" class="Fields-input"
              data-view="confirmemail" formControlName="confirmEmail" data-di-id="#userConfirmEmail" />

            <span *ngIf="
                !!userInfoForm.get('emailInfo.confirmEmail').value &&
                userInfoForm.get('emailInfo.confirmEmail').invalid &&
                userInfoForm.get('emailInfo.confirmEmail').touched
              " class="defaultMessage" role="alert" aria-live="assertive">{{
              "userRegistration.userInformation.email.invalidEmail"
              | cxTranslate
              }}</span>

            <span *ngIf="
                !!userInfoForm.get('emailInfo.confirmEmail').value &&
                !userInfoForm.get('emailInfo.confirmEmail').invalid &&
                userInfoForm.get('emailInfo').errors?.emailNotMatch
              " class="errorMessage">{{
              "userRegistration.userInformation.confirmEmail.emailNotMatch"
              | cxTranslate
              }}</span>
            <span *ngIf="
                userInfoForm.get('emailInfo.confirmEmail').errors?.required &&
                !userInfoForm.get('emailInfo.confirmEmail').valid &&
                userInfoForm.get('emailInfo.confirmEmail').touched
              " class="defaultMessage" role="alert" aria-live="assertive">{{
              "userRegistration.userInformation.requiredMsg" | cxTranslate
              }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 p0 Fields-container small-device">
      <div class="phone-extension">
        <div class="phone-container">
          <label for="formPhone" class="Fields-label">{{
            "userRegistration.userInformation.phone.label" | cxTranslate
            }}</label>
          <input id="formPhone" placeholder="xxxxxxxxxx" maxlength="10" type="text" class="Fields-input phone-no-mask"
            formControlName="phoneNumber" autocomplete="off" data-di-id="#formPhone" />

          <span class="errorMessage" *ngIf="
              !!userInfoForm.get('phoneNumber').value &&
              !userInfoForm.get('phoneNumber').valid &&
              userInfoForm.get('phoneNumber').touched
            ">{{
            "userRegistration.userInformation.phone.ph_validation_msg"
            | cxTranslate
            }}</span>
          <span *ngIf="
              userInfoForm.get('phoneNumber').errors?.required &&
              !userInfoForm.get('phoneNumber').valid &&
              userInfoForm.get('phoneNumber').touched
            " class="errorMessage" role="alert" aria-live="assertive">{{ "userRegistration.userInformation.requiredMsg"
            | cxTranslate }}
          </span>
        </div>

      </div>
    </div>
    <hr class="Fields-container-line billingPage" />
    <h3 class="pdp-title">
      {{ "userRegistration.userInformation.anotherSubTitle" | cxTranslate }}
    </h3>
    <div>
      <label for="userEmailConsent" id="usrEmailConsentLabel" class="Fields-label-new">{{
        "userRegistration.userInformation.emailConsent.label" | cxTranslate
        }}</label>
      <div class="custom-control custom-radio custom-control-inline">
        <input type="radio" class="custom-control-input" id="acceptEmailConsent" name="emailConsent"
          [value]="isEmailConsent[0]" formControlName="emailConsent" />
        <label class="custom-control-label radio_align" for="acceptEmailConsent">{{
          "userRegistration.userInformation.emailConsent.accepted"
          | cxTranslate
          }}</label>
      </div>
      <div class="custom-control custom-radio custom-control-inline">
        <input type="radio" class="custom-control-input" id="rejectEmailConsent" name="emailConsent"
          [value]="isEmailConsent[1]" formControlName="emailConsent" />
        <label class="custom-control-label radio_align" for="rejectEmailConsent">{{
          "userRegistration.userInformation.emailConsent.rejected"
          | cxTranslate
          }}</label>
      </div>
    </div>
    <div class="noemailUpdates" style="display: block"
      *ngIf="altEmailSection && userInfoForm.get('emailConsent').value">
      <div formGroupName="alternateEmailInfo">
        <div class="col-xs-12 col-sm-12 col-md-12 p0 Fields-container">
          <label for="altEmail" class="Fields-label">
            {{
            "userRegistration.userInformation.alternateEmail.label"
            | cxTranslate
            }}
          </label>
          <input id="altEmail" name="alternateEmail" data-valid="valid_mail" type="text"
            class="Fields-input field-optional" data-view="altemail" formControlName="alternateEmail" autocomplete="off"
            data-di-id="#altEmail" />

          <a (click)="deleteAltEmailSection()" title="" class="alternate-mail-delete"
            data-di-id="di-id-503c60dc-e8d584e6">
            <img src="../../assets/icons/trash.svg" alt="Delete" />{{
            "userRegistration.userInformation.alternateEmail.delete_alt_section_text"
            | cxTranslate
            }}
          </a>

          <span *ngIf="
              (userInfoForm.get('alternateEmailInfo.alternateEmail').errors
                ?.email ||
                userInfoForm.get('alternateEmailInfo.alternateEmail').errors
                  ?.pattern) &&
              !userInfoForm.get('alternateEmailInfo.alternateEmail').valid &&
              userInfoForm.get('alternateEmailInfo.alternateEmail').touched
            " class="defaultMessage" role="alert" aria-live="assertive">{{
            "userRegistration.userInformation.email.invalidEmail"
            | cxTranslate
            }}</span>
          <span *ngIf="
              userInfoForm.get('alternateEmailInfo.alternateEmail').errors
                ?.required &&
              !userInfoForm.get('alternateEmailInfo.alternateEmail').valid &&
              userInfoForm.get('alternateEmailInfo.alternateEmail').touched
            " class="defaultMessage" role="alert" aria-live="assertive">{{
            "userRegistration.userInformation.requiredMsg" | cxTranslate }}
          </span>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 p0 Fields-container">
          <label for="confirmAltEmail" class="Fields-label">
            {{
            "userRegistration.userInformation.alternateEmail.confirm_alt_email_label"
            | cxTranslate
            }}
          </label>
          <input id="confirmAltEmail" name="confirmAlternateEmail" data-valid="valid_mail" type="text"
            class="Fields-input field-optional" data-view="confirmaltemail" formControlName="confirmAlternateEmail"
            autocomplete="off" data-di-id="#confirmAltEmail" />

          <span *ngIf="
              (userInfoForm.get('alternateEmailInfo.confirmAlternateEmail')
                .errors?.email ||
                userInfoForm.get('alternateEmailInfo.confirmAlternateEmail')
                  .errors?.pattern) &&
              !userInfoForm.get('alternateEmailInfo.confirmAlternateEmail')
                .valid &&
              userInfoForm.get('alternateEmailInfo.confirmAlternateEmail')
                .touched
            " class="defaultMessage" role="alert" aria-live="assertive">{{
            "userRegistration.userInformation.email.invalidEmail"
            | cxTranslate
            }}</span>
          <span *ngIf="
              !!userInfoForm.get('alternateEmailInfo.confirmAlternateEmail')
                .value &&
              !userInfoForm.get('alternateEmailInfo.confirmAlternateEmail')
                .invalid &&
              userInfoForm.get('alternateEmailInfo').errors?.altEmailNotMatch
            " class="errorMessage">{{
            "userRegistration.userInformation.notMatchMsg" | cxTranslate
            }}</span>
          <span *ngIf="
              userInfoForm.get('alternateEmailInfo.confirmAlternateEmail')
                .errors?.required &&
              !userInfoForm.get('alternateEmailInfo.confirmAlternateEmail')
                .valid &&
              userInfoForm.get('alternateEmailInfo.confirmAlternateEmail')
                .touched
            " class="defaultMessage" role="alert" aria-live="assertive">{{
            "userRegistration.userInformation.requiredMsg" | cxTranslate }}
          </span>
          <br>
        </div>
      </div>
    </div>
    <div>
      <label for="smsConsent" id="usrSmsConsentLabel" class="Fields-label-new marginTop20">{{
        "userRegistration.userInformation.smsConsent.label" | cxTranslate
        }}</label>
      <div class="custom-control custom-radio custom-control-inline">
        <input type="radio" class="custom-control-input" id="acceptSmsConsent" name="smsConsent"
          [value]="isSmsConsent[0]" formControlName="smsConsent" />
        <label class="custom-control-label radio_align" for="acceptSmsConsent">{{
          "userRegistration.userInformation.smsConsent.accepted"
          | cxTranslate
          }}</label>
      </div>
      <div class="custom-control custom-radio custom-control-inline">
        <input type="radio" class="custom-control-input" id="rejectSmsConsent" name="smsConsent"
          [value]="isSmsConsent[1]" formControlName="smsConsent" />
        <label class="custom-control-label radio_align" for="rejectSmsConsent">{{
          "userRegistration.userInformation.smsConsent.rejected"
          | cxTranslate
          }}</label>
      </div>
    </div>
    <div>
      <br>
      <div class="custom-control custom-check custom-control-inline">
        <input type="checkbox" class="custom-control-input" id="termsAndConditions"
          formControlName="termsAndConditions">
        <label class="custom-control-label radio_align" for="termsAndConditions">
          {{
          "userRegistration.userInformation.tandcAcceptance.label"
          | cxTranslate
          }}
        </label>
        <div id="custom-link-text">
          <a href="/fr/fr/EUR/legal-notice" target="_blank">{{
            "userRegistration.userInformation.tandcAcceptanceLink.label"
            | cxTranslate
            }}</a>
        </div>
      </div>
      <div *ngIf="userInfoForm.get('termsAndConditions').errors && userInfoForm.get('termsAndConditions').touched">
        <span class="errorMessage">
          {{"userRegistration.userInformation.tandcAcceptance.required" | cxTranslate}}
        </span>
      </div>
    </div>
    <hr *ngIf="altEmailSection && userInfoForm.get('emailConsent').value" class="Fields-container-line userInfoPage" />
    <div class="invoice-btngrps" [ngClass]="
        !!altEmailSection && userInfoForm.get('emailConsent').value
          ? 'alt-section'
          : ''
      ">
      <button type="submit" class="btn btn-default oi-blue-button reviewvalidbtn" data-di-id="di-id-7a6cb2cd-cb37948a">
        {{ "userRegistration.userInformation.submit_button" | cxTranslate }}
      </button>
    </div>
  </form>
</div>
