import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LanguageService, TranslationService} from '@spartacus/core';
import {ICON_TYPE} from '@spartacus/storefront';
import {UserAccountFacade} from '@spartacus/user/account/root';
import * as html2pdf from 'html2pdf.js';
import * as moment from 'moment';
import {defineLocale} from 'ngx-bootstrap/chronos';
import {BsLocaleService} from 'ngx-bootstrap/datepicker';
import {enGbLocale} from 'ngx-bootstrap/locale';
import { frLocale } from 'ngx-bootstrap/locale';
import {Subscription} from 'rxjs';
import {LocalCurrencyPipe} from 'src/app/shared/pipes/localcurency';
import {MakeAPaymentService} from '../../custom-make-a-payment/make-a-payment.service';
import {DataLayerService} from '../../shared/services/data-layer.service';
import {CommonUtils} from '../../shared/utils/common.utils';
import {OrderHistoryService} from '../order-history.service';
import {AccountType} from "../../custom-user-registration/registration.constants";

@Component({
  selector: 'app-list-orders',
  templateUrl: './list-orders.component.html',
  styleUrls: ['./list-orders.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListOrdersComponent implements OnInit, OnDestroy {
  ordersList = [];
  orders = [];
  ordersResults: any = [];
  userId;
  timeFrameList = ['Tout', '30 jours', '6 mois', '1 an'];
  pageLength = '18 par page';
  pageList = [this.pageLength, '36 par page', '72 par page', 'Toutes'];
  timeFrame = this.timeFrameList[3];
  statusesList = [
    'Tout',
    'Créée',
    'En cours de préparation',
    'Partiellement expédiée',
    'Expédiée',
    'Cloturée',
    'Annulé',
    'Mise à jour en cours',
    'Mise à jour'
  ];
  statuses = 'Tout';
  fromDate: any;
  toDate: any;
  poNumber: any;
  order: any;
  invoice: any;
  dateError = false;
  selectedTimeFrame = 'Tout';
  selectedStatuses = 'Tout';
  pageSizeperPage = this.pageLength;
  pageSize: number = 18;
  pageSizeDisplay: number = 18;
  curPage: number = 0;
  totalPages: number;
  end: number = this.pageSize;
  @ViewChild('facetsRwd', { static: false }) private readonly facetsRwd;
  singleDateError = false;
  facetsList: any = [];
  filters = {
    fields: 'DEFAULT',
    timeFrame: '1',
    pageSize: this.pageSize,
    currentPage: this.curPage,
  };
  maxSelectionDate = new Date();
  dateFormat = 'DD/MM/YYYY';
  userDataSubscription: any;
  userInfo: any;
  FilteredData: any;
  tableType: any;
  tableOrder: any;
  tableStatus: any;
  tablePo: any;
  tableInvoice: any;
  tableOrderAmount: any;
  tableTypeSub: Subscription;
  tableOrderSub: Subscription;
  tableStatusSub: Subscription;
  tablePoSub: Subscription;
  tableInvoiceSub: Subscription;
  tableOrderAmountSub: Subscription;
  currentLang: String;
  currentLangSub: Subscription;
  excelCLicked: boolean = false;
  totalResults: number;
  hospital: boolean;
  orderDeliveryNotes: any[] = [];
  deliveryNotesSub: any;
  orderOlderThanYear: boolean = false;
  minSelectionDate: Date;

  constructor(
    private readonly service: OrderHistoryService,
    private readonly user: UserAccountFacade,
    private readonly cd: ChangeDetectorRef,
    private readonly modalService: NgbModal,
    private readonly localeService: BsLocaleService,
    private localCurrency: LocalCurrencyPipe,
    private translation: TranslationService,
    private languageService: LanguageService,
    private readonly makeAPayment: MakeAPaymentService,
    private readonly dataLayerService: DataLayerService
  ) {
    defineLocale('fr', frLocale);
    this.localeService.use('fr');
    this.minSelectionDate = new Date();
    this.minSelectionDate.setFullYear(this.maxSelectionDate.getFullYear() - 3);
  }

  ngOnInit() {
    this.hospital = sessionStorage.getItem('accountType') == AccountType.Hospital;
    const sessionTimeFrame = sessionStorage.getItem("timeframe");
    if (sessionTimeFrame !== undefined && sessionTimeFrame !== 'undefined' && sessionTimeFrame !== null)
    {
      this.filters.timeFrame = sessionTimeFrame;
      if (sessionTimeFrame == 'All') {
        this.timeFrame = 'Tout';
      }
      else if (sessionTimeFrame == '30'){
        this.timeFrame = '30 jours';
      }
      else if(sessionTimeFrame == '6') {
        this.timeFrame = '6 mois';
      }
      else if(sessionTimeFrame == '1') {
        this.timeFrame = '1 an';
      }
    }

    this.getOrderList(this.filters, 'oninit');
    this.currentLangSub = this.languageService.getActive().subscribe((res) => {
      this.currentLang = res;
    });
  }

  refine(): void {
    this.modalService.open(this.facetsRwd);
  }

  hide(): void {
    this.modalService.dismissAll(this.facetsRwd);
  }

  generateInvoicePDF(invoiceNo, systemId) {
    this.makeAPayment.downloadPdfWithSystemId(invoiceNo, systemId);
  }

  getOrderList(filterNew, origin) {
    this.userInfo = this.user.get().subscribe((userData) => {
      this.userId = userData;
      if (userData) {
        const params = { baseSiteId: 'fr', userId: this.userId.userName };
        this.service.getOrderHistory(params, filterNew);
        this.userDataSubscription = this.service.userData$.subscribe((res) => {
          if (res !== null) {
            this.totalResults = res?.pagination?.totalResults;
            this.ordersList = res?.orders;
            this.totalPages = res?.pagination?.totalPages;
            this.curPage = res?.pagination?.currentPage;
            this.formatOrders();
            this.ordersList = CommonUtils.sortArr(
              'placed',
              this.orders,
              'date',
              -1
            );
            if (origin === 'applyfilter') {
              if (this.pageSize >= this.totalResults) {
                this.end = this.totalResults;
              }
            }
            if (this.end > this.totalResults) {
              this.end = this.totalResults;
            }
            this.cd.detectChanges();
          }
        });
      }
    });
  }

  loadDeliveryNotes(orderCode: string) {
    this.orderDeliveryNotes = [];
    const params = { baseSiteId: 'fr', userId: this.userId.userName, orderCode:orderCode };
    this.service.getDeliveryNotes(params);

    this.deliveryNotesSub = this.service.deliveryNotes$.subscribe((res) => {
      if (res) {
        this.orderDeliveryNotes = res.deliveryNotes;
      }
    });
  }

  downloadDeliveryNotePdf(dNoteUrl: string) {
    const paramStr = dNoteUrl.substring(dNoteUrl.indexOf('?') + 1);
    const params = {baseSiteId: 'fr', userId: this.userId.userName, paramStr: paramStr};
    this.service.downloadDeliveryNotePdf(params);
  }

  ngOnDestroy() {
    this.userDataSubscription.unsubscribe();
    this.userInfo.unsubscribe();
    if (this.excelCLicked) {
      this.tableTypeSub.unsubscribe();
      this.tableOrderSub.unsubscribe();
      this.tableStatusSub.unsubscribe();
      this.tablePoSub.unsubscribe();
      this.tableInvoiceSub.unsubscribe();
      this.tableOrderAmountSub.unsubscribe();
      this.currentLangSub.unsubscribe();
    }
  }

  formatOrders() {
    this.orders = [];
    this.ordersList.forEach((element) => {
      const dateOnly = element.placed.split('T');
      const data = {
        code: element.code,
        guid: element.guid,
        invoices: element.invoices,
        sapSystemType:
          element && element.sapSystemType ? element.sapSystemType : '',
        placed: element.placed,
        placedDate: dateOnly[0],
        purchaseOrderNumber: element.purchaseOrderNumber,
        orderType: element.orderType,
        shipToId: element.shipToId,
        shipToName: element.shipToName,
        status: element.status,
        statusDisplay: element.statusDisplay,
        total: element.total,
        tracking: element.tracking,
        systemId: element.systemId
      };
      this.orders.push(data);
    });
  }

  changePageSize() {
    this.curPage = 0;
    if (this.pageSizeperPage === this.pageLength) {
      this.pageSize = 18;
    }
    if (this.pageSizeperPage === '36 par page') {
      this.pageSize = 36;
    }
    if (this.pageSizeperPage === '72 par page') {
      this.pageSize = 72;
    }
    if (this.pageSizeperPage === 'Toutes') {
      this.pageSize = this.totalResults;
    }
    if (this.pageSize < this.ordersList?.length) {
      this.end = this.pageSize;
    }
    // const filters = { fields: 'DEFAULT', timeFrame: '6', pageSize: this.pageSize, currentPage: 0 };
    const filters = this.getfilterQuery();
    filters.pageSize = this.pageSize;
    filters.currentPage = 0;
    this.getOrderList(filters, 'changepagesize');
    this.end = this.pageSize;
    if (this.pageSize >= this.totalResults) {
      this.end = this.totalResults;
    }
  }

  pagination(type) {
    const curPage = type === 'next' ? this.curPage + 1 : this.curPage - 1;
    const filters = this.getfilterQuery();
    filters.pageSize = this.pageSize;
    filters.currentPage = curPage;
    this.getOrderList(filters, 'pagination');
    this.end = this.pageSize * (curPage * 2);
    if (this.end > this.totalResults) {
      this.end = this.totalResults;
    } else {
      this.end = this.pageSize * (curPage + 1);
    }
  }

  numberOfPages() {
    if (this.ordersList && this.totalPages && this.pageSize > 0) {
      return Math.ceil(this.totalPages / this.pageSize);
    } else {
      return 1;
    }
  }
  iconTypes = ICON_TYPE;
  sortColumn(event, fieldName, dataList, type): any {
    CommonUtils.onSortClick(event, fieldName, dataList, type); // Column Sorting
  }

  removeFacet(filter) {
    this.facetsList.forEach((element, index) => {
      if (element === filter) {
        this.facetsList.splice(index, 1);
        if (filter.type === 'date') {
          this.fromDate = undefined;
          this.toDate = undefined;
        }
        if (filter.type === 'poNumber') {
          this.poNumber = undefined;
        }
        if (filter.type === 'orderNumber') {
          this.order = undefined;
        }
        if (filter.type === 'invoiceNumber') {
          this.invoice = undefined;
        }
        if (filter.type === 'timeFrame') {
          this.timeFrame = 'Tout';
        }
        if (filter.type === 'statuses') {
          this.statuses = 'Tout';
        }
        this.cd.detectChanges();
        this.applyFilter();
      }
    });
  }

  applyFilter(type?: string) {
    if (this.fromDate && this.toDate) {
      if (this.fromDate.getTime() > this.toDate.getTime()) {
        this.dateError = true;
        return false;
      } else {
        this.dateError = false;
      }
    }
    const params = { baseSiteId: 'fr', userId: this.userId.userName };
    const filters = this.getfilterQuery();
    const statusInput = filters.statuses;
    let statusSelected;
    switch (statusInput) {
      case 'Tout':
        statusSelected = 'All';
        break;
      case 'Créée':
        statusSelected = 'CREATED';
        break;
      case 'En cours de préparation':
        statusSelected = 'INPROCESS';
        break;
      case 'Partiellement expédiée':
        statusSelected = 'PARTIALLY_COMPLETED';
        break;
      case 'Expédiée':
        statusSelected = 'COMPLETED';
        break;
      case 'Cloturée':
        statusSelected = 'CLOSED';
        break;
      case 'Annulé':
              statusSelected = 'CANCELLED';
              break;
      case 'Mise à jour en cours':
        statusSelected = 'UPDATE_IN_PROGRESS';
        break;
      case 'Mise à jour':
        statusSelected = 'UPDATED';
        break;
      default:
        statusSelected = 'Tout';
        break;
    }
    this.showFacets(JSON.stringify(filters));
    filters['statuses'] = statusSelected;
    filters.pageSize = this.pageSize;
    filters.currentPage = 0;
    this.getOrderList(filters, 'applyfilter');
    this.end = this.pageSize;
    this.cd.detectChanges();
    this.selectedTimeFrame = this.timeFrame;
    this.selectedStatuses = this.statuses;
    if (type === 'mobile') {
      this.hide();
    }
    return true;
  }

  showFacets(data) {
    const facets = JSON.parse(data);
    delete facets.fields;
    if (facets.fromDate) {
      facets.date = `${moment(this.fromDate).format(
        this.dateFormat
      )} - ${moment(this.toDate).format(this.dateFormat)}`;
      delete facets.fromDate;
      delete facets.toDate;
    }
    if (facets.timeFrame) {
      facets.timeFrame = this.timeFrame;
    }
    this.facetsList = Object.entries(facets).map(([type, value]) => ({
      type,
      value,
    }));
  }

  getfilterQuery() {
    const data: any = {
      fields: 'DEFAULT',
    };
    if (this.invoice) {
      data.invoiceNumber = this.invoice;
    }
    if (this.order) {
      data.orderNumber = this.order;
    }
    if (this.poNumber) {
      data.poNumber = this.poNumber;
    }
    if (this.timeFrame && !this.fromDate && !this.toDate) {
      if (this.timeFrame === 'Tout') {
        data.timeFrame = 'All';
      }
      if (this.timeFrame === '30 jours') {
        data.timeFrame = '30';
      }
      if (this.timeFrame === '6 mois') {
        data.timeFrame = '6';
      }
      if (this.timeFrame === '1 an') {
        data.timeFrame = '1';
      }
    }
    if (this.fromDate && this.toDate) {
      if (this.timeFrame === 'Tout' && data.timeFrame) {
        delete data.timeFrame;
      }
      data.fromDate = moment(this.fromDate).format('DD-MM-YYYY');
      data.toDate = moment(this.toDate).format('DD-MM-YYYY');
    } else if (
      (!this.fromDate && this.toDate) ||
      (this.fromDate && !this.toDate)
    ) {
      this.singleDateError = true;
    }
    if (this.statuses) {
      data.statuses = this.statuses;
    }

    sessionStorage.setItem("timeframe", data.timeFrame);
    return data;
  }

  validateDate() {
    this.timeFrame = this.fromDate || this.toDate ? 'Tout' : this.timeFrame;
    this.statuses = 'Tout';
    if (this.fromDate && this.toDate) {
      this.singleDateError = false;
      if (this.fromDate.getTime() > this.toDate.getTime()) {
        this.dateError = true;
      } else {
        this.dateError = false;
      }
    }
  }

  clearDateRange() {
    this.fromDate = undefined;
    this.toDate = undefined;
  }

  generateSavePdf() {
    const options = {
      margin: [10, 10, 10, 10],
      filename: 'Order History.pdf',
      image: { type: 'jpeg' },
      html2canvas: {},
      jsPDF: { orientation: 'portrait' },
    };
    const element: Element = document.getElementById('ordersTable');
    html2pdf().from(element).set(options).save();
    const pdfDataLayer = {
      localizedDownloadedMedia: {
        name: options?.filename,
      },
      downloadedMediaType: 'pdf',
    };
    this.dataLayerService.downloadDataLayer(pdfDataLayer);
  }

  generateSaveXls() {
    this.excelCLicked = true;
    const list = JSON.parse(JSON.stringify(this.ordersList));
    const orders = [];
    list.forEach((element) => {
      const dateOnly = element.placed.split('T');
      const invoices = Array.prototype.map
        .call(element.invoices, function (item) {
          return item.invoiceNumber;
        })
        .join(',');
      this.tableTypeSub = this.translation
        .translate('ordersList.type')
        .subscribe((res) => {
          this.tableType = res;
        });
      this.tableOrderSub = this.translation
        .translate('ordersList.order')
        .subscribe((res) => {
          this.tableOrder = res;
        });
      this.tableStatusSub = this.translation
        .translate('ordersList.orderStatus')
        .subscribe((res) => {
          this.tableStatus = res;
        });
      this.tablePoSub = this.translation
        .translate('ordersList.po')
        .subscribe((res) => {
          this.tablePo = res;
        });
      this.tableInvoiceSub = this.translation
        .translate('ordersList.invoice')
        .subscribe((res) => {
          this.tableInvoice = res;
        });
      this.tableOrderAmountSub = this.translation
        .translate('ordersList.orderAmount')
        .subscribe((res) => {
          this.tableOrderAmount = res;
        });

      const currencyFormated = this.localCurrency.transform(
        element.total.value
      );
      orders.push({
        Date: moment(dateOnly[0]).format(this.dateFormat),
        [this.tableType]: element.sapSystemType,
        [this.tableOrder]: element.code,
        [this.tableStatus]: element.statusDisplay,
        [this.tablePo]: element.purchaseOrderNumber,
        [this.tableInvoice]: invoices,
        [this.tableOrderAmount]: currencyFormated,
      });
    });
    const excelDataLayer = {
      localizedDownloadedMedia: {
        name: 'OrderHistory.xlsx',
      },
      downloadedMediaType: 'xlsx',
    };
    this.dataLayerService.downloadDataLayer(excelDataLayer);
    this.service.downloadXls(orders, 'OrderHistory');
  }

  clearFilters() {
    this.facetsList = [];
    this.invoice = undefined;
    this.fromDate = undefined;
    this.toDate = undefined;
    this.order = undefined;
    this.invoice = undefined;
    this.poNumber = undefined;
    this.timeFrame = 'Tout';
    this.statuses = 'Tout';
    const filters = {
      fields: 'DEFAULT',
      timeFrame: 'All',
      pageSize: this.pageSize,
      currentPage: 0,
    };
    this.getOrderList(filters, 'clearfilters');

    if (this.end > this.totalResults) {
      this.end = this.totalResults;
    } else {
      this.end = this.pageSize;
    }
  }

  validateInvoiceDate( invoiceDateValue:Date): boolean{
    const currentDate = new Date();
    const invoiceDate = new Date(invoiceDateValue);
    const difference = currentDate.getTime() - invoiceDate.getTime();
    return Math.round(difference / (1000 * 3600 * 24)) <= 548;
  }
}
