<section class="custom-product-card">
  <section class="custom-card-header">
    <section>

      <a (click)="itemSelected(productInfo)">

        <cx-media class="cx-product-image" [container]="productInfo.images?.PRIMARY" format="product"
          [alt]="productInfo.summary"></cx-media>
      </a>
      <a [routerLink]="['/product',productInfo.code,productInfo.code]"
        *ngIf="productInfo?.images === undefined || productInfo?.images?.length === 0">
        <img class="image-set-in-card product-img" [src]="fallbackImage" alt="">
      </a>

    </section>
    <section class="product-name">
      <a [routerLink]="['/product',productInfo.code,productInfo.code]">
        <h6>{{productInfo.name}}</h6>
      </a>
    </section>
  </section>

  <section class="custom-card-body" *ngIf=!iconDisplay>
    <p class="custom-tab-p display-type" [innerHTML]="sanitizer.bypassSecurityTrustHtml(description|slice:0:300)">
    </p>
    <a class="show-more display-type" *ngIf="description?.length>300"
      [routerLink]="['/product',productInfo.code,productInfo.code]">Afficher plus...</a>
  </section>

  <section class="custom-card-body" *ngIf=iconDisplay>
    <section class="info-tabs">

      <app-my-tabs>

        <app-my-tab tabIcon="information.svg" class="product-detail">
          <div class="padding-0">
            <p class="custom-tab-title">{{'plp.details'| cxTranslate }}</p>
            <p class="custom-tab-p display-type" *ngIf="description !== undefined"
              [innerHTML]="sanitizer.bypassSecurityTrustHtml(description|slice:0:300)"></p>
            <a class="show-more display-type" *ngIf="description?.length>300"
              [routerLink]="['/product',productInfo.code,productInfo.code]">Afficher plus...</a>
          </div>
        </app-my-tab>

        <app-my-tab *ngIf="productInfo.batchInfo?.batchNumber" tabIcon="icn-batchexpiry.svg">
          <div class="padding-0">
            <p class="custom-tab-title">{{'plp.batchNumber'|cxTranslate}}</p>
            <p class="batchinfo-p"> {{productInfo.batchInfo?.batchNumber}}</p>
            <p class="custom-tab-title">{{'plp.expiryDate'|cxTranslate}}</p>
            <p class="batchinfo-p"> {{productInfo.batchInfo?.expiryDate}}</p>
            <p class="custom-tab-p">{{'plp.batchInfo'|cxTranslate}}</p>
          </div>
        </app-my-tab>

        <app-my-tab *ngIf="monographLink" tabIcon="excel-sample.svg">
          <div class="padding-0">
            <p class="custom-tab-title">{{'plp.monograph'|cxTranslate}}</p>
            <p class="custom-tab-p">
              <img class="pdf-icon" src="../../assets/icons/pdf.png" alt="">
              <a alt="" [href]="monographLink" target="_blank"
                class="file-link blue">{{'plp.monograph'|cxTranslate}}</a>
            </p>
          </div>
        </app-my-tab>


        <app-my-tab *ngIf="productInfo?.productUpdates" tabIcon="warning.svg">
          <div class="padding-0">
            <p class="custom-tab-title">{{'plp.productUpdate'|cxTranslate}}</p>
            <p class="custom-tab-p productupdate-p">{{productInfo?.productUpdates|slice:0:300}}</p>
            <a class="show-more display-type" *ngIf="productInfo?.productUpdates?.length>300"
              [routerLink]="['/product',productInfo.code,productInfo.code]">Afficher plus...</a>
          </div>
        </app-my-tab>
      </app-my-tabs>
    </section>
  </section>
  <section class="custom-card-footer">

    <section class="prod-num">
      <b class="desc-title" *ngIf="!hospital && productInfo?.cipCode">{{'plp.cipCode'|cxTranslate}} : <span class="desc-details">{{productInfo?.cipCode}}</span>
      </b>
      <b class="desc-title" *ngIf="hospital && productInfo?.ucdCode">{{'plp.ucdCode'|cxTranslate}} : <span class="desc-details">{{productInfo?.ucdCode}}</span>
      </b>
    </section>
    <section *ngIf="userDetails !== undefined">

      <section>
        <section *ngIf="(!productInfo.flu || (hospital && !activeFluSeason) || (!hospital && productInfo.productType === 'INDIRECT' && isIndirect())) && (!isIndirect() || isIndirectPage() || (hospital && productInfo.productType === 'INDIRECT'))" class="row unit-calc"
                 [ngClass]="{'visible-hide': productInfo?.price == null || productInfo?.price == undefined || (productInfo?.price?.value == 0.0 && productInfo?.productType !== 'BEYFORTUS')}">

          <section class="no-price text-center"
            *ngIf="productInfo?.price == null || productInfo?.price == undefined || (productInfo?.price?.value == 0.0 && productInfo?.productType !== 'BEYFORTUS')">
            <p>{{'plp.noPrice' | cxTranslate}}</p>
          </section>

          <section class="price-details">
            <b *ngIf="!hospital">{{'plp.yourPrice'|cxTranslate}}</b>
            <b *ngIf="hospital">{{'plp.ucdPrice'|cxTranslate}}</b>
            <section class="price-total">
              <span *ngIf="productInfo?.price == undefined || productInfo?.price?.value === undefined">-</span>
              <span *ngIf="productInfo?.price && productInfo?.price?.value !== undefined">
                <span>{{productInfo?.price?.value | localCurrency}}</span> {{'plp.cad'|cxTranslate}}
              </span>
            </section>
          </section>

          <section class="units" *ngIf="!productInfo?.discontinued && productInfo?.available">

            <b class="prodUnits">
              {{ hospital ? ('plp.hospitalUnits' | cxTranslate) : ('plp.units' | cxTranslate) }}
            </b>
            <section class="unit-area">
              <span class="disabled " aria-hidden="true" id="{{'minusIcon'+productInfo.code}}" (click)="performQuantityComputation(
                  productInfo,
                  2,
                  productInfo?.code,
                  productInfo.stepQuantity,
                  productInfo?.minQuantity,
                  productInfo?.maxQuantity,
                  productInfo?.entryNumber
                  )">
                <img src="../../assets/icons/less.svg" id="{{'unitMinus'+productInfo.code}}" class="disabled-img"
                  alt="reduce-quantity">
                <img src="../../assets/icons/less.svg" id="{{'unitMinusDisable'+productInfo.code}}"
                  alt="reduce-quantity">
              </span>
              <input type="number" *ngIf="!productInfo.numberOfDosesPerUnit; else numberOfBoxesInput" class="prod-quantity text-center" [value]="productInfo?.stepQuantity" (keyup)="performQuantityComputation(
                  productInfo,
                  null,
                  productInfo?.code,
                  productInfo.stepQuantity,
                  productInfo?.minQuantity,
                  productInfo?.maxQuantity,
                  productInfo?.entryNumber
                )" onkeypress="return /[0-9a-zA-Z]/i.test(event.key)" id="{{'prodQuantity'+productInfo.code}}"
                maxlength="7" />
                <ng-template #numberOfBoxesInput >
                  <input
                    type="number"
                    class="prod-quantity text-center"
                    [value]="roundedValue ? roundedValue : productInfo.numberOfDosesPerUnit"
                    (input)="userInput = $event.target.value" 
                    (blur)="roundToNearestMultipleOfStepQuantity(productInfo.stepQuantity,productInfo?.code)"
                    id="{{'prodQuantity'+productInfo?.code}}" min="1" max="9999"
                  />
                </ng-template>
              <span class="" aria-hidden="true" id="{{'plusIcon'+productInfo.code}}" (click)="performQuantityComputation(
                  productInfo,
                  1,
                  productInfo?.code,
                  productInfo.stepQuantity,
                  productInfo?.minQuantity,
                  productInfo?.maxQuantity,
                  productInfo?.entryNumber
                  )">
                <img src="../../assets/icons/more.svg" id="{{'unitPlus'+productInfo.code}}" alt="increase-quantity">
                <img src="../../assets/icons/more-disable.svg" id="{{'unitPlusDisable'+productInfo.code}}"
                  class="disabled-img" alt="increase-quantity-disabled">
              </span>

              <div *ngIf="productInfo.numberOfDosesPerUnit" class="doses-section" id="{{'noOfDoses'+productInfo?.code}}">
                <span *ngIf="dividedValue < 1; else dividedValueNumber">
                  {{ productInfo.numberOfDosesPerUnit / productInfo.numberOfDosesPerUnit }} {{'plp.boxes'|cxTranslate}}
                </span>
                <ng-template #dividedValueNumber>
                  {{ dividedValue }} {{'plp.boxes'|cxTranslate}}
                </ng-template>
              </div>
            </section>
          </section>

          <section  *ngIf="!productInfo?.discontinued && productInfo?.available && isIndirect()" class="col-md-12 col-lg-12 col-xl-12 final-price">
            <span>{{'plp.priceDisclaimerInfo'|cxTranslate}}</span>
          </section>

          <section class="col-md-12 col-lg-12 col-xl-12 vol-Pricing mb-3"
            *ngIf="!productInfo?.discontinued && productInfo?.available">
            <a class="link vol-Price" placement="top" *ngIf="productInfo?.volumePrices?.length > 0"
              [ngbPopover]="popContent" popoverTitle="{{'plp.volumePricing'|cxTranslate}}">
              {{'plp.volumePricing'|cxTranslate}}
            </a>
            <ng-template #popContent>
              <table class="tb" id="vol-price-pdp" aria-describedby="volTable">
                <thead>
                  <th scope="col"></th>
                </thead>
                <tbody *ngFor="let prices of productInfo?.volumePrices; let i=index">
                  <tr>
                    <td>{{prices?.minQuantity}} - {{prices?.maxQuantity}}
                      {{'selectVaccines.units'|cxTranslate}}:
                    </td>
                    <td>{{prices?.value | localCurrency}}</td>
                    <td><span *ngIf="i>0" class="save-price">{{'selectVaccines.save'|cxTranslate}}
                        {{productInfo?.volumePrices[0].value - productInfo?.volumePrices[i].value | localCurrency}}
                        (
                        {{(productInfo?.volumePrices[0].value - productInfo?.volumePrices[i].value) /
                        productInfo?.volumePrices[0].value | percent:'1.2-2'}}
                        )
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </ng-template>
          </section>

          <section class="product-info" *ngIf="!productInfo?.discontinued && productInfo?.available">

            <section *ngIf="productInfo.sapAllocation">
              <b class="prod-allocation">{{'plp.productAllocation'|cxTranslate}}</b>
              <p class="allocation-p">{{'plp.allocationDetails' | cxTranslate}}
            </section>
          </section>
          <section class="product-info-message"
            *ngIf="productInfo?.messageDangerousGoods && !productInfo?.discontinued">
            <b class="prod-allocation">{{'plp.messageDangerousGoods'|cxTranslate}}</b>
          </section>
          <section class="product-info-message" *ngIf="productInfo?.messageColdChain && !productInfo?.discontinued">
            <b class="prod-allocation">{{'plp.messageColdChain'|cxTranslate}}</b>
          </section>

          <button type="button" class="btn blue-button" *ngIf="!productInfo?.discontinued && productInfo?.available"
            [ngbPopover]="popTitleSuccess" placement="bottom" triggers="manual" #p2="ngbPopover"
            popoverClass="savesuccess-msg" placement="bottom" [closeDelay]="500"
            (click)="addToCart(productInfo?.code,p2)"
            [disabled]="isIndirectPage() && !userDetails.wholesalerId">
            <i *ngIf="buttonName === 'Reserve now'" class="fa fa-calendar" aria-hidden="true"></i>
            <span *ngIf="buttonName==='Add to cart'">{{'addToCartItems.addCart'|cxTranslate}}</span>
            <span *ngIf="buttonName==='Login to order'">{{'addToCartItems.loginToOrder'|cxTranslate}}</span>
            <span *ngIf="buttonName==='Reserve now'">{{'addToCartItems.reserveNow'|cxTranslate}}</span>
          </button>
          <ng-template #popTitleSuccess>
            <div class="cart-value-added">
              <img src="../../../assets/icons/confirmation-positive-green.png" class="positive-green-icon"
                alt="success">
              <span class="confirmation-itemsadded">{{itemAddedQuantity}}
                {{ ('addToCartItems.' + (hospital ? 'addEntrySuccessHospital' : 'addEntrySuccess')) | cxTranslate }}
              </span>
            </div>
          </ng-template>

        </section>

        <section *ngIf="productInfo?.discontinued || !productInfo?.available">
          <section *ngIf="productInfo?.discontinued && productInfo?.available">
            <p class="product-discontinued-txt1">
              {{'plp.discontinued'|cxTranslate}}</p>
          </section>
          <section *ngIf="productInfo?.discontinued && !productInfo?.available">
            <p class="product-discontinued-txt1">
              {{'plp.discontinued'|cxTranslate}}</p>
          </section>
          <section *ngIf="!productInfo.discontinued && !productInfo?.available" class="m0">
            <b class="prod-allocation" *ngIf="productInfo?.availabilityText">{{productInfo?.availabilityText}}</b>
            <b class="prod-allocation "
              *ngIf="!productInfo?.availabilityText">{{'plp.productUnavailable'|cxTranslate}}</b>
          </section>

        </section>

        <section *ngIf="productInfo.flu && (!hospital || hospital && activeFluSeason)">

          <section>
            <p *ngIf="productInfo.available && !productInfo.discontinued" class="contact-info m0">
              {{'plp.productAllocationInfo1'|cxTranslate}}
            </p>

            <button type="button" class="btn blue-button m0-spacing"
              *ngIf="productInfo.available && !productInfo.discontinued"
              (click)="addToCart(productInfo?.code,p2)">
              <i *ngIf="buttonName === 'Reserve now'" class="fa fa-calendar" aria-hidden="true"></i>
              <span *ngIf="buttonName==='Add to cart'">{{'addToCartItems.addCart'|cxTranslate}}</span>
              <span *ngIf="buttonName==='Login to order'">{{'addToCartItems.loginToOrder'|cxTranslate}}</span>
              <span class="reserve-now-button"
                *ngIf="buttonName==='Reserve now'">{{'addToCartItems.reserveNow'|cxTranslate}}</span>
            </button>

          </section>
        </section>

        <section *ngIf="isIndirect() && !isIndirectPage() && !hospital">
          <section>
            <p *ngIf="isIndirect() && productInfo.available && !productInfo.discontinued" class="contact-info m0">
              {{'plp.productAllocationInfoIndirect'|cxTranslate}}
            </p>
            <button type="button" class="btn blue-button m0-spacing"
              *ngIf="productInfo?.productType === 'INDIRECT' && productInfo.available && !productInfo.discontinued"
              (click)="addToCart(productInfo?.code,p2)">
              <i *ngIf="buttonName === 'Reserve now'" class="fa fa-calendar" aria-hidden="true"></i>
              <span *ngIf="buttonName==='Add to cart'">{{'addToCartItems.addCart'|cxTranslate}}</span>
              <span *ngIf="buttonName==='Login to order'">{{'addToCartItems.loginToOrder'|cxTranslate}}</span>
              <span class="reserve-now-button"
                *ngIf="buttonName==='Reserve now'">{{'addToCartItems.reserveNow'|cxTranslate}}</span>
            </button>
          </section>
        </section>

      </section>


    </section>
  </section>
  <section *ngIf="userDetails == undefined">
    <button type="button" class="btn blue-button" (click)="goToLogin()">
      <span *ngIf="buttonName==='Add to cart'">{{'addToCartItems.addCart'|cxTranslate}}</span>
      <span *ngIf="buttonName==='Login to order'">{{'addToCartItems.loginToOrder'|cxTranslate}}</span>
      <span class="reserve-now-button"
        *ngIf="buttonName==='Reserve now'">{{'addToCartItems.reserveNow'|cxTranslate}}</span>
    </button>
  </section>
</section>
