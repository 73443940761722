<section class="row row-20">
  <section class="container-pdp" *ngIf="displaySection">
    <aside aria-label="Product Details" class="col-xs-12 col-sm-4 col-md-4 right-navigation float-right">
      <a *ngIf="productDetails?.images != undefined || productDetails?.images?.length > 0" (click)="openImage(content)">

        <cx-media class="cx-product-image" [container]="productDetails?.images?.PRIMARY" format="product"
          [alt]="productDetails?.summary"></cx-media>

      </a>
      <a *ngIf="productDetails?.images === undefined || productDetails?.images?.length === 0">
        <img class="image-set-in-card product-img" [src]="fallbackImage" alt="">
      </a>
      <ng-template #content let-modal>
        <ngb-carousel *ngIf="productDetails?.images != undefined || productDetails?.images?.length > 0"
          ngClass="productDetails?.images?.GALLERY.length > 0 ? 'carousel-nopagination' : 'carousel-pagination' ">
          <ng-template ngbSlide>
            <div class="picsum-img-wrapper">
              <!-- Show zoom format if it exist in resp -->
              <ng-container *ngIf="productDetails?.images?.GALLERY.zoom != null">
                <cx-media class="cx-product-image carousel-pdp" [container]="productDetails?.images?.GALLERY?.zoom"
                  format="zoom" [alt]="img">
                </cx-media>
                <div class="xzoom-thumbs">
                  <a class="item">
                    <cx-media class="cx-product-image carousel-zoom" [container]="productDetails?.images?.GALLERY?.zoom"
                      format="zoom" [alt]="img">
                    </cx-media>
                  </a>
                </div>
              </ng-container>
              <!-- Show primary product if no zoom exist in resp -->
              <ng-container *ngIf="productDetails?.images?.GALLERY.zoom == null">
                <cx-media class="cx-product-image carousel-pdp" [container]="productDetails?.images?.PRIMARY?.product"
                  format="product" [alt]="img">
                </cx-media>
                <div class="xzoom-thumbs">
                  <a class="item">
                    <cx-media class="cx-product-image carousel-zoom"
                      [container]="productDetails?.images?.PRIMARY?.product" format="product" [alt]="img">
                    </cx-media>
                  </a>
                </div>
              </ng-container>
            </div>
          </ng-template>
        </ngb-carousel>
        <!-- <ngb-carousel *ngIf="productDetails?.images != undefined || productDetails?.images?.length > 0"
                    ngClass="productDetails?.images?.GALLERY.length > 0 ? 'carousel-nopagination' : 'carousel-pagination' ">
                    <ng-template ngbSlide *ngFor="let carousel of productDetails?.images?.GALLERY">
                        <div class="picsum-img-wrapper">
                            <cx-media class="cx-product-image carousel-pdp" [container]="carousel?.zoom" format="zoom"
                                [alt]="img">
                            </cx-media>
                            <div class="xzoom-thumbs">
                                <a class="item">
                                    <cx-media class="cx-product-image carousel-zoom" [container]="carousel?.zoom"
                                        format="zoom" [alt]="img">
                                    </cx-media>
                                </a>
                            </div>
                        </div>
                    </ng-template>
                </ngb-carousel> -->
      </ng-template>
      <aside aria-label="Product Details Pdf " class="col-sm-3 right-navigation float-left resources-section "
        *ngIf="monographLink ">
        <section>
          <section class=" prod-detail-pdfs ">
            <p class=" pdfs-title ">
              {{'plp.resources'|cxTranslate}}</p>
            <p class=" pdfs-list ">

              <img class=" pdf-icon " src=" ../../assets/icons/pdf.png " alt=" ">
              <a alt=" " [href]="monographLink" target=" _blank "
                class=" file-link blue ">{{'plp.monograph'|cxTranslate}}</a>
            </p>
          </section>
        </section>
      </aside>
    </aside>

    <section class="col-xs-12 col-sm-8 col-md-8 left-section pb-4 float-left">
      <!-- <h3 class="pdp-title">{{productDetails?.name}}</h3> -->
      <p class="desc-class-pdp" [innerHTML]="description"></p>
      <section class="batch-info">
        <p class="desc-title" *ngIf="productDetails?.batchInfo?.batchNumber">{{'plp.batchNumber'|cxTranslate}}
          <span class="desc-details">{{productDetails?.batchInfo?.batchNumber}}</span>
        </p>
        <p class="desc-title" *ngIf="productDetails?.batchInfo?.batchNumber">{{'plp.expiryDate'|cxTranslate}} :
          <span class="desc-details">{{productDetails?.batchInfo?.expiryDate}}</span>
        </p>
        <p *ngIf="productDetails?.batchInfo?.batchNumber">{{'plp.batchInfo'|cxTranslate}}</p>
        <p class="desc-title" *ngIf="!hospital && productDetails?.cipCode">{{'pdp.cipCode'|cxTranslate}} :
          <span class="desc-details">{{productDetails?.cipCode}}</span>
        </p>
        <p class="desc-title" *ngIf="hospital && productDetails?.ucdCode">{{'pdp.ucdCode'|cxTranslate}} :
          <span class="desc-details">{{productDetails?.ucdCode}}</span>
        </p>
        <hr class="line">
        <section *ngIf="userDetails !== undefined">
          <section *ngIf="(!productDetails?.flu || (hospital && !activeFluSeason)) && !productDetails?.discontinued && (!isIndirect() || (isIndirect() && hospital))">
           <section class="third-col">
              <section class="row unit-calc">
                <section class="col-md-4 col-xs-6 bottom-spacing price-area"
                  *ngIf="(productDetails?.productType !== undefined && productDetails?.productType === 'BEYFORTUS') || !productDetails?.price?.value == 0.0 || !productDetails?.price?.value == 0.00">
                  <b *ngIf="!hospital">{{'plp.yourPrice'|cxTranslate}}</b>
                  <b *ngIf="hospital">{{'plp.ucdPrice'|cxTranslate}}</b>
                  <section class="number">
                    <span
                      *ngIf="productDetails?.price == undefined || productDetails?.price?.value === undefined">-</span>
                    <span *ngIf="productDetails?.price && productDetails?.price?.value !== undefined">
                      <span>{{productDetails?.price?.value | localCurrency}}</span>
                      {{'plp.cad'|cxTranslate}}
                    </span>
                  </section>

                  <section *ngIf="productDetails?.price && productDetails?.price?.value !== undefined && productDetails?.productType !== 'BEYFORTUS'">
                    <span>
                      {{'plp.priceDisclaimerInfo'|cxTranslate}}
                    </span>
                  </section>
                </section>
              </section>
              <section class="col-md-4 col-xs-6 bottom-spacing units-area"
                *ngIf="(productDetails?.price != null && productDetails?.price != undefined && ((productDetails?.productType !== undefined && productDetails?.productType === 'BEYFORTUS') || productDetails?.price?.value != 0.0)) && !productDetails?.discontinued">
                <section>
                  <section *ngIf="productDetails?.available">
                    <b class="prodUnits">{{ hospital ? ('plp.hospitalUnits' | cxTranslate) : ('plp.units' | cxTranslate) }}</b>
                    <section class="unit-area">
                      <span class="disabled " aria-hidden="true" id="{{'minusIcon'+productDetails?.code}}">
                        <img src="../../assets/icons/less.svg" id="{{'unitMinus'+productDetails?.code}}"
                          class="disabled-img" alt="decrease-quantity" (click)="performQuantityComputation(
                                  productDetails,
                                  2,
                                  productDetails?.code,
                                  productDetails?.stepQuantity,
                                  productDetails?.minQuantity,
                                  productDetails?.maxQuantity
                                  )">
                        <img src="../../assets/icons/less-disable.svg" id="{{'unitMinusDisable'+productDetails?.code}}"
                          alt="decrease-quantity-disabled">
                      </span>
                      <input type="number" *ngIf="!productDetails.numberOfDosesPerUnit; else numberOfBoxesInput" class="prod-quantity text-center" [value]="initialQuantity"
                        (keyup)="performQuantityComputation(
                          productDetails,
                                    null,
                                  productDetails?.code,
                                  productDetails?.stepQuantity,
                                  productDetails?.minQuantity,
                                  productDetails?.maxQuantity
                          )" id="{{'prodQuantity'+productDetails?.code}}" min="1" max="9999"
                        onkeypress="return /[0-9a-zA-Z]/i.test(event.key)" />
                        <ng-template #numberOfBoxesInput >
                          <!-- <input type="number" class="prod-quantity text-center" [value]="initialQuantity"
                          id="{{'prodQuantity'+productDetails?.code}}" min="1" max="9999"
                          /> -->
                          <input
                          type="number"
                          class="prod-quantity text-center"
                          [value]="roundedValue ? roundedValue : initialQuantity"
                          (input)="userInput = $event.target.value;"
                          (blur)="roundToNearestMultipleOfStepQuantity(productDetails?.stepQuantity)"
                          id="{{'prodQuantity'+productDetails?.code}}" min="1" max="9999"
                          />
                        </ng-template>

                      <span class="" aria-hidden="true" id="{{'plusIcon'+productDetails?.code}}">
                        <img src="../../assets/icons/more.svg" id="{{'unitPlus'+productDetails?.code}}" alt="" (click)="performQuantityComputation(
                          productDetails,
                          1,
                          productDetails?.code,
                          productDetails?.stepQuantity,
                          productDetails?.minQuantity,
                          productDetails?.maxQuantity
                          )">
                        <img src="../../assets/icons/more-disable.svg" id="{{'unitPlusDisable'+productDetails?.code}}"
                          class="disabled-img" alt="">
                      </span>

                      <div *ngIf="productDetails.numberOfDosesPerUnit" class="doses-section" id="{{'noOfDoses'+productDetails?.code}}">
                      <span *ngIf="dividedValue < 1; else dividedValueNumber">
                        {{ productDetails.numberOfDosesPerUnit / productDetails.numberOfDosesPerUnit }} {{'plp.boxes'|cxTranslate}}
                      </span>
                        <ng-template #dividedValueNumber>
                          {{ dividedValue }} {{'plp.boxes'|cxTranslate}}
                        </ng-template>
                      </div>

                    </section>
                  </section>
                </section>
              </section>
              <section class="col-md-12 col-xs-12 bottom-spacing units-area text-right no-price-container"
                *ngIf="((!productDetails.maxQuantity || !productDetails.minQuantity) && productDetails?.price?.value == 0) || (productDetails?.price?.value == undefined || ((productDetails?.productType !== undefined &&productDetails?.productType !== 'BEYFORTUS') && (productDetails?.basePrice?.value == 0.0 || productDetails?.basePrice?.value == 0))) && !productDetails?.discontinued">
                <section class="no-price text-center">
                  <p>{{'plp.noPrice' | cxTranslate}}</p>
                </section>
              </section>
             <section class="col-md-4 col-sm-4 col-xs-12 bottom-spacing button-area"
                      *ngIf="((hospital && productDetails?.price?.value != 0) ||
                      (productDetails?.productType === 'BEYFORTUS') ||
                      (productDetails?.price?.value && productDetails?.price?.value != 0)) &&
                      !productDetails?.discontinued &&
                      productDetails?.available &&
                      (!productDetails?.flu || hospital) &&
                      (productDetails?.productType !== 'INDIRECT' || (productDetails?.productType === 'INDIRECT' && hospital))">
               <button class="blue-button login-btn ml10 added-success" data-di-id="di-id-2fea5a72-b2e23002"
                       [ngbPopover]=popTitleSuccess placement="bottom" triggers="manual" #p2="ngbPopover" placement="bottom"
                       popoverClass="pdp-itemsuccess-msg" (click)="addToCart(productDetails?.code, p2)"
                       [disabled]="wrongQuantity">
                 <i *ngIf="buttonName === 'Reserve now'" class="fa fa-calendar" aria-hidden="true"></i>
                 <span *ngIf="buttonName==='Add to cart'">{{'addToCartItems.addCart'|cxTranslate}}</span>
                 <span *ngIf="buttonName==='Login to order'">{{'addToCartItems.loginToOrder'|cxTranslate}}</span>
                 <span class="reserve-now-button"
                       *ngIf="buttonName==='Reserve now'">{{'addToCartItems.reserveNow'|cxTranslate}}</span>
               </button>
             </section>
            </section>

            <section class="pdp-message-area" *ngIf="productDetails?.messageDangerousGoods">
              <b class="prod-allocation">{{'plp.messageDangerousGoods'|cxTranslate}}</b>
            </section>
            <section class="pdp-message-area" *ngIf="productDetails?.messageColdChain">
              <b class="prod-allocation">{{'plp.messageColdChain'|cxTranslate}}</b>
            </section>
            <hr class="line">
            <!-- Packing List Section -->
            <section class="sap-allocation-area" *ngIf="!productDetails?.discontinued && productDetails?.available">
              <section *ngIf="productDetails?.sapAllocation === true">
                <b class="prod-allocation">{{'plp.productAllocation'|cxTranslate}}</b>
                <p class="allocation-p">{{'plp.allocationDetails' | cxTranslate}}
              </section>
            </section>
          </section>
          <section *ngIf="!hospital">
            <p class="desc-title">
              {{'pdp.packagingDimensions'|cxTranslate}}:
              <span class="desc-details">{{productDetails?.packagingDimensions}}</span>
            </p>
          </section>

          <section *ngIf="productDetails?.discontinued || !productDetails?.available">
            <section *ngIf="productDetails?.discontinued && productDetails?.available">
              <p class="text-center product-discontinued-txt1">
                {{'plp.discontinued'|cxTranslate}}</p>
            </section>
            <section *ngIf="productDetails?.discontinued && !productDetails?.available">
              <p class="text-center product-discontinued-txt1">
                {{'plp.discontinued'|cxTranslate}}</p>
            </section>
            <section *ngIf=" !productDetails?.discontinued && !productDetails?.available">
              <section class="text-center1">
                <b class="text-center product-discontinued-txt1"
                  *ngIf="productDetails?.availabilityText">{{productDetails?.availabilityText}}</b>
                <b class="text-center product-discontinued-txt1"
                  *ngIf="!productDetails?.availabilityText">{{'plp.productUnavailable'|cxTranslate}}</b>
              </section>
            </section>
          </section>

          <section class="added-success-container">
            <ng-template #popTitleSuccess>
              <div class="cart-value-added">
                <img src="../../../assets/icons/confirmation-positive-green.png" class="positive-green-icon"
                  alt="success">
                <span class="confirmation-itemsadded">{{itemAddedQuantity}}
                  {{ ('addToCartItems.' + (hospital ? 'addEntrySuccessHospital' : 'addEntrySuccess')) | cxTranslate }}</span>
              </div>
            </ng-template>
          </section>

          <section class="flu-class"
            *ngIf="productDetails.flu && (!hospital || hospital && activeFluSeason) && productDetails?.available && !productDetails?.discontinued">
            <section>
              <p class="contact-info">
                {{'plp.productAllocationInfo1'|cxTranslate}}
              </p>
            </section>

            <section class="col-md-4 col-sm-4 col-xs-12 button-area flu-button-area"
              *ngIf="productDetails.flu && (!hospital || hospital && activeFluSeason) && productDetails?.available && !productDetails?.discontinued">
              <button class="blue-button login-btn ml10 flu-button" data-di-id="di-id-2fea5a72-b2e23002 "
                [ngbPopover]=popTitleSuccess placement="bottom" triggers="manual" #p2="ngbPopover" placement="bottom"
                (click)="addToCart(productDetails?.code, p2)">
                <i *ngIf="buttonName === 'Reserve now'" class="fa fa-calendar" aria-hidden="true"></i>
                <span *ngIf="buttonName==='Add to cart'">{{'addToCartItems.addCart'|cxTranslate}}</span>
                <span *ngIf="buttonName==='Login to order'">{{'addToCartItems.loginToOrder'|cxTranslate}}</span>
                <span class="reserve-now-button"
                  *ngIf="buttonName==='Reserve now'">{{'addToCartItems.reserveNow'|cxTranslate}}</span>
              </button>
            </section>
          </section>

          <section class="flu-class"
            *ngIf="isIndirect() && productDetails?.available && !productDetails?.discontinued && !hospital">
            <section>
              <p class="contact-info">
                {{'plp.productAllocationInfoIndirect'|cxTranslate}}
              </p>
            </section>
            <section class="col-md-4 col-sm-4 col-xs-12 button-area flu-button-area"
              *ngIf="isIndirect() && productDetails?.available && !productDetails?.discontinued">
              <button class="blue-button login-btn ml10 flu-button" data-di-id="di-id-2fea5a72-b2e23002 "
                [ngbPopover]=popTitleSuccess placement="bottom" triggers="manual" #p2="ngbPopover" placement="bottom"
                (click)="addToCart(productDetails?.code, p2)">
                <i *ngIf="buttonName === 'Reserve now'" class="fa fa-calendar" aria-hidden="true"></i>
                <span *ngIf="buttonName==='Add to cart'">{{'addToCartItems.addCart'|cxTranslate}}</span>
                <span *ngIf="buttonName==='Login to order'">{{'addToCartItems.loginToOrder'|cxTranslate}}</span>
                <span class="reserve-now-button"
                  *ngIf="buttonName==='Reserve now'">{{'addToCartItems.reserveNow'|cxTranslate}}</span>
              </button>
            </section>
          </section>
        </section>

        <!-- The End -->
        <section>
          <section *ngIf="userDetails == undefined" class="col-md-4 col-sm-4 col-xs-12 button-area">
            <button type="button" class="btn blue-button" (click)="goToLogin()">
              <span *ngIf="buttonName==='Add to cart'">{{'addToCartItems.addCart'|cxTranslate}}</span>
              <span *ngIf="buttonName==='Login to order'">{{'addToCartItems.loginToOrder'|cxTranslate}}</span>
              <span class="reserve-now-button"
                *ngIf="buttonName==='Reserve now'">{{'addToCartItems.reserveNow'|cxTranslate}}</span>
            </button>
          </section>

          <section *ngIf="userDetails !== undefined">

            <section *ngIf="!productDetails?.discontinued && productDetails?.available"
              class="products-accordion clear-fix pt2">
              <section class="panel-group">
                <section class="panel panel-default" *ngIf="productDetails?.volumePrices?.length > 0">
                  <section class="panel-heading panel-endborder">
                    <section class="first-level-acc">
                      <label><img src="../../assets/icons/price-master.png" class="accordion-img" alt="">
                        {{'plp.volumePricing'|cxTranslate}}</label>
                      <span data-toggle="collapse" data-parent="#accordion" href="#pdp-list1" class="acc-plus-icon fa"
                        aria-expanded="panelVolumeExpanded" (click)="panelVolumeExpanded = !panelVolumeExpanded"
                        [ngClass]="panelVolumeExpanded?'fa-minus':'fa-plus collapsed'"></span>
                    </section>
                  </section>
                  <section id="pdp-list1" class="panel-collapse collapse" aria-expanded="panelVolumeExpanded"
                    (click)="panelVolumeExpanded = !panelVolumeExpanded" [ngClass]="panelVolumeExpanded?'in':''">
                    <section class="panel-body">
                      <section class="discount-list" id="vol-price-pdp">
                        <table class="tb" id="tb-vol-price-pdp" aria-describedby="pdpTable">
                          <thead>
                            <th id=""></th>
                          </thead>
                          <tbody *ngFor="let prices of productDetails?.volumePrices; let i=index">
                            <tr>
                              <td>{{prices?.minQuantity}} - {{prices?.maxQuantity}}
                                {{'plp.units'|cxTranslate}}:
                              </td>
                              <td>{{prices?.value | localCurrency}}</td>
                              <td><span *ngIf="i>0" class="save-price">{{'plp.save'|cxTranslate}}
                                  {{productDetails?.volumePrices[0].value - productDetails?.volumePrices[i].value
                                  |localCurrency}}
                                  ({{(productDetails?.volumePrices[0].value -
                                  productDetails?.volumePrices[i].value)/productDetails?.volumePrices[0].value |
                                  percent:'1.2-2'}})</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </section>
                    </section>
                  </section>
                </section>
              </section>
            </section>

          </section>
          <section class=" products-accordion clear-fix">
            <section class="panel-group ">
              <!-- update2 -->
              <section class="panel panel-default " *ngIf="productDetails?.productUpdates ">
                <section class="panel-heading ">
                  <section class="first-level-acc ">
                    <label><img src="../../assets/icons/info.png " class="accordion-img "
                        alt=" ">{{'pdp.productUpdate'|cxTranslate}}
                    </label> <span data-toggle="collapse " data-parent="#accordion " href="#pdp-list3 "
                      class="acc-plus-icon fa " aria-expanded="panelProductExpanded "
                      (click)="panelProductExpanded=! panelProductExpanded "
                      [ngClass]="panelProductExpanded? 'fa-minus': 'fa-plus collapsed' "></span>
                  </section>
                </section>
                <section id="pdp-list3 " class="panel-collapse collapse " aria-expanded="panelProductExpanded "
                  (click)="panelProductExpanded=! panelProductExpanded " [ngClass]="panelProductExpanded? 'in': '' ">
                  <section class="panel-body productupdate-p ">
                    {{productDetails?.productUpdates}}</section>
                </section>
              </section>
            </section>
          </section>
        </section>
      </section>
    </section>
  </section>

</section>
