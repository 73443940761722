<!-- =========================LEFT SECTION============================================= -->
<div class="container header-Container">
  <div class="row">
    <div class="col-lg-3 col-xl-3 reservation-Div">
      <span class="reservation-Id"
        >{{ "influenzaReservation.reservationId" | cxTranslate }}
        {{ reservationId }}</span
      >
      <div>
        <span class="activeSeason-Id"
          >{{ "selectVaccines.season" | cxTranslate }} {{ seasonId }} |</span
        ><span class="status-Id">{{ statusDisplay }}</span>
      </div>
    </div>
    <div class="col-lg-7 col-xl-7 bc-Dash-Div">
      <span class="back-To-Dashbaord" (click)="backToDashBoard()">
        <img src="assets/img/chevron-left.svg" class="svg-Images" alt="left" />
        {{ "selectVaccines.back_to_db" | cxTranslate }}</span
      >
    </div>
    <div class="col-lg-1 col-xl-1 print-Div">
      <span class="print" (click)="OnFluPrint()">
        <img src="assets/img/print.svg" class="svg-Images" alt="print" />
        {{ "selectVaccines.print" | cxTranslate }}</span
      >
    </div>
  </div>
</div>
<section class="container influenzaCategory-Container">
  <section>
    <aside
      class="col-lg-4 col-md-4 col-sm-12 right-navigation float-right totals-width p0 est-Rwd"
    >
      <section class="container estimation-Container">
        <section class="row est-Bground">
          <section class="col-md-12 col-lg-12 col-xl-12 text-center">
            <div class="est-total">
              {{ "influenzaReservation.reservationTotal" | cxTranslate }}
              {{ subTotal ? (subTotal | localCurrency) : 0 }}
            </div>
          </section>
        </section>
        <section class="col-md-12 col-lg-12 col-xl-12 total-Price-Section">
          <app-total-prices-order
            [orderDetails]="orderData"
          ></app-total-prices-order>
        </section>
      </section>
    </aside>
    <section
      class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 bord-Right border-Right float-left shipments-Section"
    >
      <section class="row">
        <section class="col-sm-6">
          <h3>{{ "orderConfirmation.myShipments" | cxTranslate }}</h3>
        </section>
        <section class="col-sm-6 text-right">
          <ng-container *ngIf="editButtonConfig | async as config">
            <div
              *ngIf="
                config?.isUpdateAllowed && !hospital;
                else editReservationMax
              "
            >
              <button
                class="btn resBtn text-button-initial mb-2"
                (click)="fluEditReserveUpdate(config?.totalMinDosesThreshold)"
              >
                {{ "orderConfirmation.editReservation" | cxTranslate }}
              </button>
            </div>
            <ng-template #editReservationMax>
              <div *ngIf="config?.availableUpdates === 0 && !hospital && config?.alreadyUpdated">
                <button class="btn resBtn text-button-initial mb-4 btn-disabled" disabled>
                  {{ 'orderConfirmation.editReservationMax' | cxTranslate }}
                </button>
              </div>
            </ng-template>
          </ng-container>
        </section>
      </section>

      <app-flu-charts></app-flu-charts>

      <section class="container p-0">
        <section class="row">
          <section
            class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 myPresentations-Section"
          >
            <h3>{{ "orderConfirmation.myPresentations" | cxTranslate }}</h3>

            <table aria-describedby="cardDetailsTable" class="table">
              <thead>
                <tr class="header-row">
                  <th scope="col" class="header"></th>
                  <th scope="col" class="header"></th>
                  <th scope="col" class="header">
                    {{ "orderConfirmation.unitsDoses" | cxTranslate }}
                  </th>
                  <th scope="col" class="header">
                    {{ "selectVaccines.purchasePrice" | cxTranslate }}
                  </th>
                </tr>
              </thead>

              <tbody *ngFor="let product of displayProductForUiArray">
                <tr
                  class="content-row"
                  *ngIf="(product?.quantityStatus != 'Annulé' || product?.quantity > 0) && product?.productType !== 'GOODIES'"
                >
                  <td>
                    <a (click)="productDetails(product)">
                      <img
                        [src]="product.thumbNailImg"
                        class="prod-Image"
                        alt="prodImg"
                        *ngIf="product.thumbNailImg !== ''; else fallBack"
                      />

                      <ng-template #fallBack>
                        <img
                          [src]="fallbackImage"
                          class="prod-Image"
                          title="{{ product.name }}"
                          alt="defImg"
                        />
                      </ng-template>
                    </a>
                  </td>
                  <td class="prod-td">
                    <a (click)="productDetails(product)">
                      <span class="prodName" [innerHTML]="product?.prodName">
                      </span>
                    </a>
                    <section
                      class="col-md-12 col-lg-12 col-xl-12 prod-No-Section"
                      *ngIf="!hospital && product?.cipCode !== undefined"
                    >
                      {{ "selectVaccines.cipCode" | cxTranslate }}:
                      <span class="prodct-No">{{ product?.cipCode }}</span>
                    </section>
                    <section
                      class="col-md-12 col-lg-12 col-xl-12 prod-No-Section"
                      *ngIf="hospital && product?.ucdCode !== undefined"
                    >
                      {{ "selectVaccines.ucdCode" | cxTranslate }}:
                      <span class="prodct-No">{{ product?.ucdCode }}</span>
                    </section>
                  </td>
                  <td>
                    {{ product.quantity }}
                  </td>
                  <td>
                    <span class="trackingNumber">
                      {{ product?.basePrice | localCurrency }}
                      <!-- <section *ngIf="estimatedDeliveryDate && user?.onsn==='ON'">
                        <section><span class="estimatedDelivery-Date-header">Estimated Delivery date</span></section>
                        <span class="estimatedDelivery-Date">{{estimatedDeliveryDate | date:'dd/MM/yyyy'}}</span>
                      </section> -->
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
        </section>
      </section>
    </section>

    <!-- ==================RIGHT Section=================================== -->

    <section
      class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 right-Section-Top-Col-Span float-right"
    >
      <app-flu-confirmation-right-content></app-flu-confirmation-right-content>
    </section>
  </section>
</section>
